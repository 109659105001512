.user_decrease_content {
    font-size: 16px;
    background-color: #eeeeee;
  }
  @media screen and (min-width: 320px) and (max-width: 600px) {
    .user_decrease_content {
      position: relative;
      margin-left: 88px;
    }
  }
  