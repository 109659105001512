.nav_items_container_login {
  font-family: var(--font-poppins);
  padding-left: 90px;
  display: flex;
  align-items: center;
  gap: 16px;
  white-space: nowrap;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #a3a3a3 !important;
}
.navbar-nav .nav-link {
  color: #a3a3a3 !important;
}
.navbar-nav .nav-link:hover {
  color: #1b1b1b !important;
}

.dropdown-toggle::after {
  display: inline-block !important;
}
.outline_btn {
  box-sizing: border-box;
  display: flex;
  padding: 8px 16px;
  background: #ffffff;
  border: 1px solid #595fde;
  border-radius: 25px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #595fde;
}
.fill_btn {
  box-sizing: border-box;
  display: flex;
  padding: 8px 16px;
  background: #595fde;
  border: 1px solid #595fde;
  border-radius: 25px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: white;
}

.main_content {
  background-color: #acafef;
  padding-top: 70px;
  padding-bottom: 70px;
  display: flex;
  justify-content: center;
  margin-bottom: 99px;
  font-family: var(--font-poppins);
  position: relative;
  overflow: hidden;
}
.main_container {
  width: 1200px;
}
.admin_login_layout {
  background: #595fde;
  height: 732px;
}
.signin_container {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  padding: 36px 20px;
  width: 500px;
  position: relative;
  margin: auto;
  top: 150px;
}
.signin_container > h1 {
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  color: rgb(57, 66, 99);
  text-align: center;
}
.error_message {
  padding: 0.75rem 1.25rem;
  margin-top: 1.5rem;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.signin_wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
  position: relative;
  margin-top: 48px;
}
.signin_wrapper > input {
  border: 0px;
  height: 45px;
  padding: 5px 10px;
  font-size: 18px;
  line-height: 1.42857143;
  color: #7d7d7d;
  background-color: transparent;
  background-image: none;
  border-bottom: 1px solid #ddd;
  box-shadow: none !important;
  outline: none !important;
}
.forgot {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #888888;
  text-align: center;
}
.forgot:hover {
  color: #555555;
}
.custom_modal_admin > .modal-dialog > .modal-content {
  padding: 12px;
}
.custom_modal_admin_body {
  gap: 0px !important;
  color: #595f6f;
  font-size: 16px;
  font-weight: 700;
}
.custom_modal_admin_body > input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.signin_btn {
  background: #595fde;
  border: 1px solid #595fde;
  color: white;
  gap: 24px;
  height: 46px;
  padding: 8px 24px 8px 24px;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
}

.auth_btn {
  width: 237px;
  height: 54px;
  gap: 15px;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  align-items: center;
}

.auth_btn p {
  font-family: var(--SF-Pro-Display);
  font-style: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.38px;
  color: #666666;
  margin-bottom: 0px;
}
.auth_btn_two {
  width: 225px;
  margin-left: 16px;
}
.vector {
  left: 360px;
  top: 0px;
  position: absolute;
  z-index: 0;
  overflow: hidden;
}
.vector_two {
  position: absolute;
  left: 315px;
  top: 0px;
  z-index: 0;
}
.features {
  position: relative;
  width: 100%;
  margin-top: max(30px);
}
.feature_one {
  width: 281px;
  height: 191px;
  /* margin-left: 349px;
margin-bottom: 56px; */
  position: relative;
  left: 316px;
  bottom: 30px;
  padding: 24px;
  gap: 24px;
  border-radius: 10px;
  background: #9598e8;
  z-index: 2;
  box-shadow: 0px 5px 20px 0px #595fde1a;
}
.feature_one h5 {
  font-family: var(--font-poppins);
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  color: white;
}
.feature_one p {
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: white;
}
.feature_two {
  width: 383px;
  height: 210px;
  position: relative;
  bottom: 8px;
  left: 63px;
  padding: 24px;
  gap: 24px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0px 5px 20px 0px #595fde1a;
}
.feature_two h5 {
  font-family: var(--font-poppins);
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  color: #9c9feb;
  margin-top: 16px;
}
.feature_two p {
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #999999;
}
.feature_three {
  width: 282px;
  height: 108px;
  position: relative;
  top: 15px;
  left: 255px;
  padding: 24px;
  gap: 24px;
  border-radius: 15px;
  background-color: #9598e8;
  box-shadow: 0px 5px 20px 0px #595fde33;
}
.feature_three span {
  display: block;
}
.feature_three h5 {
  font-family: var(--font-poppins);
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  color: white;
}
.feature_four {
  width: 282px;
  height: 118px;
  position: relative;
  top: 37px;
  left: 305px;
  padding: 24px;
  gap: 24px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0px 5px 20px 0px #595fde1a;
}
.feature_four h5 {
  font-family: var(--font-poppins);
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  color: #9c9feb;
  margin-top: 16px;
}

.btn_container {
  display: flex;
  gap: 16px;
}
.info_wrapper {
  display: flex;
  justify-content: center;
}
.info_container {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  font-family: var(--font-poppins);
}
.footer {
  background-color: #232536;
  display: flex;
  justify-content: center;
  padding-top: 57px;
  padding-bottom: 57px;
}
.footer_container {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
}
.footer_copyright {
  width: 30%;
}
.footer_item_container {
  display: flex;
  justify-content: end;
  gap: 40px;
}
.footer_item_container > p {
  max-width: 30%;
}
.subs_inner {
  width: 483px;
  height: 70px;
  padding: 0px 0.5px 0px 0.5px;
}

.subs_input {
  border: none;
  outline: none;
  background: #e3e5ff;
  border-radius: 10px 0px 0px 10px;
  padding: 23px;
}
.subs_text {
  background: #ccceff;
  border-radius: 0px 17px 17px 0px;
  padding: 23px;
  font-family: var(--font-poppins);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  text-align: center;
}
.subs_box h5 {
  margin-bottom: 26px;
  font-size: 25px;
  font-weight: 600;
  line-height: 37.5px;
  text-align: left;
}
.main_text {
  margin-top: 23px;
  margin-bottom: 54px;
  font-size: 20px;
  font-weight: 500;
  line-height: 29px;
  text-align: left;
}
.secondary_text {
  margin-bottom: 143px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.links_container {
  margin: 0px 80px 84px 0px;
  width: 174px;
}
.faq_content {
  margin-top: 20px;
  margin-bottom: 138px;
  width: 478px;
  height: 400px;
  /* gap: 10px; */
}
.question {
  border-bottom: 1px solid #c2c9d1;
}
.faq_content p {
  padding: 15px 10px 10px 10px;
  width: 478px;
  height: 40px;
  font-family: var(--font-poppins);
}
.question .logo {
  position: relative;
  top: 17px;
  width: 20px;
  height: 20px;
  padding: 2.5px;
}
.options h2 {
  font-family: var(--font-poppins);
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: 600;
  line-height: 37.5px;
  text-align: left;
}
.options li {
  list-style: none;
  font-family: var(--font-poppins);
  width: 253px;
  font-size: 18px;
  font-weight: 400;
  line-height: 32.4px;
  text-align: left;
}
.contact_detail {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0.75)
    ),
    #c2c9d1;

  width: 487px;
  height: 101px;
  position: relative;
  top: 91px;
  right: 13px;
  padding: 20px 30px 20px 30px;
  gap: 10px;
  border-radius: 10px 10px 0px 0px;
  color: #232536;
}
.contact_detail img {
  position: absolute;
  right: 30px;
  bottom: 10px;
}
.help_btn {
  position: relative;
  left: 450px;
  bottom: 45px;
}

.signup_container {
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(89, 95, 222, 0.4);
  border-radius: 15px;
  padding: 88px 55px;
  z-index: 2;
}

.signup_container > h1 {
  font-weight: 700;
  font-size: 64px;
  line-height: 96px;
  color: #000000;
  margin-bottom: 64px;
  text-align: left;
}
.signup_wrapper {
  display: flex;
  flex-direction: column;
  width: 478px;
  height: 634px;
  gap: 24px;
  position: relative;
}
.name_container {
  display: flex;
  gap: 16px;
}
.select_container {
  position: relative;
}
.select_image {
  position: absolute;
  bottom: 16%;
  right: 3%;
}

.password_container {
  display: flex;
  gap: 16px;
  position: relative;
}
.visible_two {
  position: absolute;
  top: 20%;
  left: 41%;
}
.visible_three {
  position: absolute;
  top: 20%;
  right: 2%;
}

.forgot_container {
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(89, 95, 222, 0.4);
  border-radius: 15px;
  padding: 86.5px 55px;
  z-index: 3;
}
.forgot_container h1 {
  font-weight: 700;
  font-size: 50px;
  line-height: 75px;
  color: #000000;
  margin-bottom: 64px;
  text-align: left;
}

.forgot_wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
}
.new_pass_container {
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(89, 95, 222, 0.4);
  border-radius: 15px;
  padding: 36.5px 55px;
  z-index: 2;
}
.new_pass_container h1 {
  font-weight: 700;
  font-size: 50px;
  line-height: 75px;
  color: #000000;
  margin-bottom: 64px;
  text-align: left;
}
.new_pass_wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
}
.custom_link {
  color: inherit;
}

@media screen and (min-width: 800px) and (max-width: 1300px) {
  .nav_items_container_login {
    font-size: 14px;
    padding-left: 50px;
  }
  .header_container {
    width: 1200px;
    gap: 1.5rem;
    margin: 14px 0px 14px 30px !important;
    padding: 0px;
  }
  .info_container {
    justify-content: space-around;
  }
  .help_btn {
    width: 60px;
  }
}

@media screen and (min-width: 320px) and (max-width: 600px) {
  .navbar {
    padding: 8px 0px !important;
  }
  .navbar-collapse {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 70%;
    background-color: #fff;
    transition: right 0.3s ease;
    z-index: 10;
  }

  .navbar-collapse.show {
    right: 0;
  }
  .navbar-toggler {
    position: absolute;
    top: 18px;
    right: 10px;
    z-index: 100;
  }
  .navbar-expand .navbar-toggler {
    display: contents;
  }
  .navbar-expand .navbar-toggler .navbar-toggler-icon {
    z-index: 20;
  }
  .nav_items_container_login {
    margin-left: 0;
    flex-direction: column !important;
    align-items: start;
    padding-left: 50px;
    gap: 20px;
  }
  .main_content {
    margin: auto;
    overflow-x: hidden;
  }
  .main_container {
    width: 100%;
  }

  .header {
    width: 100%;
    padding: 0 20px;
  }
  .header_container {
    width: 100%;
    padding: 0px 0px;
    margin: 10px 30px !important;
  }
  .logo {
    max-width: 70%;
    height: auto;
  }
  .signin_container,
  .signup_container,
  .forgot_container,
  .new_pass_container {
    width: 90%;
    padding: 40px;
    height: auto;
  }
  .signin_container h1,
  .signup_container h1,
  .forgot_container h1,
  .new_pass_container h1 {
    text-align: center;
    font-size: 36px;
    line-height: 60px;
    margin: 50px;
    margin-top: 10px;
  }
  .visible {
    position: absolute;
    top: 18.5%;
    left: 87%;
  }
  .forgot,
  .or {
    text-align: center;
    margin: auto;
  }
  .auth_btn,
  .auth_btn_two {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 5px;
    padding: 4px 10px;
  }

  .auth_btn p {
    display: none;
  }

  .auth_btn::after {
    content: "Sign In";
    font-size: 14px;
    font-weight: 600;
    margin-left: 2px;
  }

  .auth_btn_two {
    width: 237px;
    margin-left: 16px;
  }

  .auth_btn img {
    width: 14px;
  }
  .feature_one,
  .feature_two,
  .feature_three,
  .feature_four {
    display: none;
  }

  .info_wrapper {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }

  .info_container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .col-5 {
    width: 100%;
  }
  .main_text {
    width: 100%;
    font-size: 16px;
    line-height: 30px;
  }
  .secondary_text {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 60px;
  }
  .options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 270px;
  }

  .links_container,
  .submit_pr_container {
    padding-right: 0px;
    width: 48%;
    margin: 0px;
  }
  .submit_pr_container {
    padding-left: 0px;
  }
  .links_container h2,
  .submit_pr_container h2 {
    font-size: 15px;
    font-weight: 600;
    line-height: 30.5px;
  }
  .links_container li,
  .submit_pr_container li {
    width: 100%;
    font-size: 11px;
    font-weight: 400;
    line-height: 32.4px;
  }

  .faq_content {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
  }
  .faq_content p {
    width: 90%;
  }

  .question {
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }

  .subs_box h5 {
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    line-height: 37.5px;
    text-align: center;
  }

  .subs_inner {
    width: 100%;
    margin: 0px;
  }
  .subs_input {
    font-size: 12px;
  }
  .subs_text {
    font-size: 12px;
  }
  .contact_detail {
    right: 0px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    border-radius: 10px;
    position: relative;
    top: 30px;
  }
  .contact_detail h5 {
    font-size: 16px;
  }
  .contact_detail p {
    width: 45%;
    font-size: 14px;
  }
  .contact_detail .logo {
    margin: 0px auto;
    left: 15px;
  }
  .help_btn {
    width: 60px;
    height: 60px;
    position: sticky;
  }
  .footer {
    width: 100%;
    padding: 30px 5px 50px 5px;
  }
  .footer_container {
    width: 100%;
    font-size: 8px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    height: 10px;
  }
  .footer_item_container {
    display: flex;
    gap: 30px;
    width: 60%;
    text-align: center;
  }
  .mb-0 {
    width: 23%;
  }
  .signup_container {
    margin: 0px 20px;
  }
  .signup_wrapper {
    width: 100%;
  }
  .visible_two {
    position: absolute;
    top: 20%;
    left: 37%;
  }
  .forgot_container,
  .new_pass_container {
    margin: 0px 25px;
  }
}
