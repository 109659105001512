.network_create_layout {
  font-size: 16px;
  background-color: #eeeeee;
}
@media screen and (min-width: 320px) and (max-width: 600px) {
  .network_create_layout {
    position: relative;
    margin-left: 88px;
  }
}
