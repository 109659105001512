.notification_layout {
  font-family: var(--font-poppins);
  font-size: 16px;
  padding: 16px 40px 81px 40px;
}
.notification_tabs {
  margin: 0px 16px;
  display: flex;
  gap: 1.5rem;
}
.all_tab,
.read_tab,
.unread_tab {
  margin-bottom: 0px;
  padding: 15px 8px;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: #c2c9d1;
}
.tab_active {
  color: #585edb;
  border-bottom: 4px solid #585edb;
}
.notifications_list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 34px 20px;
  background-color: white;
  border-radius: 12px;
  border: 1px solid #bbbdf0;
  box-shadow: 0px 7px 20px 0px #eeeeee80;
  overflow-x: auto;
}
.notification {
  display: flex;
  gap: 11px;
}
.status_rejected {
  min-width: 12px;
  height: 12px;
  border-radius: 25%;
  background-color: #f3595a;
  margin-top: 2px;
}
.status_published {
  min-width: 12px;
  height: 12px;
  border-radius: 25%;
  background-color: #5dcc5c;
  margin-top: 2px;
}
.status_updated {
  min-width: 12px;
  height: 12px;
  border-radius: 25%;
  background-color: #c291ff;
  margin-top: 2px;
}
.status_action {
  min-width: 12px;
  height: 12px;
  border-radius: 25%;
  background-color: #f66c1f;
  margin-top: 2px;
}
.status_pending {
  min-width: 12px;
  height: 12px;
  border-radius: 25%;
  background-color: #f4bc4f;
  margin-top: 2px;
}
.status_deleted {
  min-width: 12px;
  height: 12px;
  border-radius: 25%;
  background-color: #242424;
  margin-top: 2px;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.notification h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #091e42;
  margin-bottom: 0px;
}
.notification p {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #918fbf;
  margin-bottom: 0px;
}

.notification span {
  font-family: var(--font-lato);
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.01em;
  color: #b6afcf;
}
@media screen and (min-width: 320px) and (max-width: 600px) {
  .notification_layout {
    padding: 0px;
    margin: 10px;
  }
  .notification_tabs {
    margin: 0px 6px;
    display: flex;
    gap: 1.5rem;
  }
  .all_tab,
  .read_tab,
  .unread_tab {
    padding: 15px 4px;
    font-size: 16px;
  }
}
