.contact_layout {
  background-image: url("../../../../public/build/contact_bg.png");
  background-repeat: no-repeat;
  background-size: 110vw;
  background-position: center;
  height: 1000px;
}
.contact_content {
  padding: 84px 102.5px;
  display: flex;
  gap: 24px;
  font-family: var(--font-poppins);
}
.contact_text {
  width: 58%;
}
.contact_heading {
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  color: white;
}
.contact_heading_2 {
  font-size: 25px;
  font-weight: 400;
  line-height: 37.5px;
  color: white;
}
.contact_card {
  background-color: white;
  padding: 48px;
  border-radius: 16px;
  box-shadow: 0px 16px 47px 0px #0000001a;
}
.card_text_1 {
  color: black;
  font-size: 25px;
  font-weight: 600;
  line-height: 37.5px;
  text-align: left;
}
.card_text_2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.24px;
  text-align: left;
}
.contact_form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.contact_form > input {
  border-radius: 8px;
  border: 1px solid #c2c7d0;
  padding: 12px 20px 10px 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.24px;
  color: #98a1b0;
}
.contact_form_btn {
  margin-top: 8px;
  border-radius: 25px;
  background-color: #585edb;
  border: 1px #585edb;
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 14px 32.5px;
}

@media screen and (max-width: 1300px) {
  .contact_content {
    padding: 68px 80.5px;
    gap: 8vw;
  }
  .contact_text {
    width: 49%;
  }
  .contact_heading {
    font-size: 28px;
  }
  .contact_heading_2 {
    font-size: 18px;
  }
  .contact_card {
    padding: 4vw;
  }
  .card_text_1 {
    font-size: 22px;
  }
  .card_text_2 {
    font-size: 14px;
  }
  .contact_form {
    gap: 12px;
  }
  .contact_form > input {
    padding: 8px 2vw;
    font-size: 11px;
  }
  .contact_form_btn {
    margin-top: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
}
@media screen and (min-width: 320px) and (max-width: 600px) {
  .contact_layout {
    background-repeat: repeat;
    background-size: 100% 100%;
    background-position: none;
    height: 1010px;
  }
  .contact_text {
    width: 100%;
  }
  .contact_content {
    flex-direction: column;
    padding: 3rem 1rem;
    gap: 3rem;
  }
  .contact_heading {
    font-size: 26px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 2rem;
  }
  .contact_heading_2 {
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
  }
  .contact_card {
    padding: 36px 18px;
  }
  .card_text_1 {
    font-size: 5vw;
    line-height: 24px;
  }
  .card_text_2 {
    font-size: 3vw;
    line-height: 16px;
  }
  .contact_form {
    gap: 10px;
  }
  .contact_form > input {
    padding: 8px 16px;
    font-size: 12px;
    line-height: 18px;
  }
  .contact_form_btn {
    margin-top: 6px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    padding: 12px;
  }
}
