.pr_menu {
  display: flex;
  gap: 1.5rem;
  margin: 1.5rem;
  font-size: 1em;
  font-family: var(--font-poppins);
}
.pr_menu > div {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid #bbbdf0;
  border-radius: 12px;
  padding: 1.2em;
  box-shadow: 0px 7px 20px 0px #eeeeee80;
}
.pr_num {
  width: 50%;
  height: 40px;
  font-size: 2em;
  font-weight: 600;
  line-height: 54px;
  margin-bottom: 0px;
}
.pr_text {
  width: 125px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}
.pr_img {
  width: 3.5vw;
  height: 3.5vw;
}
.my_press {
  display: flex;
  margin: 1.5rem;
  gap: 39.5rem;
  font-family: var(--font-poppins);
}
.my_press_text {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  padding: 3px 0px;
}
.my_press_btns {
  display: flex;
  gap: 16px;
}
.my_view_btn {
  width: 121px;
  height: 36px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #585edb;
  background-color: white;
  border: 1px solid white;
}
.my_add_btn {
  width: 154px;
  height: 36px;
  border-radius: 25px;
  background-color: #585edb;
  border: 1px solid #585edb;
  color: white;
}
.pr_add_img {
  margin-right: 9px;
}
.my_pr_tables {
  margin: 2%;
  border-radius: 8px;
  border: 1px solid #bbbdf0;
  overflow-x: auto;
}

.pr_tables_head {
  height: 7vw;
}
.pr_tables_head > tr {
  background-color: #f9f9f9;
  height: 71.96px;
}
.pr_tables_head > tr > th {
  background-color: #f9f9f9;
  padding: 1.5rem 0.5rem;

  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}
.pr_tables_body > tr > td {
  height: 72px;
  vertical-align: middle;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 400;
  line-height: 23.24px;
}
.draft_btn {
  width: 54px;
  height: 34px;
  border-radius: 16px;
  border: 1px solid #ebedf0;
  background-color: #ebedf0;
  color: #7a8699;
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 400;
  line-height: 20.02px;
}
.in_process_btn {
  width: 89px;
  height: 34px;
  padding: 4px;
  border-radius: 16px;
  border: 1px solid #a6aebb;
  background-color: white;
  color: #6b788e;
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 400;
  line-height: 20.02px;
}
.pending_btn {
  width: 74px;
  height: 34px;
  padding: 4px;
  border-radius: 16px;
  background-color: #fdf6e8;
  border: 1px solid #fdf6e8;
  color: #eba41b;
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 400;
  line-height: 20.02px;
}
.rejected_btn {
  width: 79px;
  height: 34px;
  padding: 4px;
  border-radius: 16px;
  background-color: #feebeb;
  border: 1px solid #feebeb;
  color: #f3595a;
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 400;
  line-height: 20.02px;
}
.published_btn {
  width: 85px;
  height: 34px;
  padding: 4px;
  border-radius: 16px;
  background-color: #ecf9eb;
  border: 1px solid #ecf9eb;
  color: #5dcc5c;
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 400;
  line-height: 20.02px;
}
.modal-content {
  padding: 48px;
}
.modal-body {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.pr_modal_head {
  text-align: center;
  color: #091e42;
  font-size: 25px;
  font-weight: 600;
  line-height: 37.5px;
}
.pr_modal_note {
  color: #091e42;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}
.pr_modal_content {
  color: #7a8699;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.pr_modal_date {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
}
.pr_modal_btn {
  display: flex;
  justify-content: center;
  gap: 24px;
}
.modal_cancel_btn {
  width: 105px;
  border-radius: 25px;
  background-color: white;
  border: 1px solid #585edb;
  color: #585edb;
}
.modal_save_btn {
  width: 180px;
  background-color: #585edb !important;
  border: 1px solid #585edb;
  border-radius: 25px;
}
.pr_download_btn {
  width: 86px;
  height: 34px;
  padding: 4px;
  border-radius: 16px;
  background-color: #585edb;
  border: 1px solid #585edb;
  color: white;
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 400;
  line-height: 20.02px;
}
.pr_note {
  color: #000e66;
  text-decoration: underline;
}

.pr_dropdown {
  width: 189px;
  padding: 3px 16px;
  display: flex;
}
.pr_dropdown > h4,
.pr_next_page > h4 {
  color: #98a1b0;
  width: 120px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  padding-top: 3px;
}
.pr_rows_dropdown {
  width: 44px;
}

.pr_rows_table {
  display: flex;
  gap: 8px;
}
.pr_pagination {
  width: 756px;
  padding: 0px 230px;
}
.pr_next_page {
  display: flex;
  gap: 8px;
}
.pr_next_page h4 {
  width: 79px;
  padding-top: 7px;
}
.pr_secondary_nav {
  height: 60px;
  background-color: #f5f9fe;
}
.pr_edit_btn {
  position: relative;
  float: right;
  margin: 12px 24px;
  width: 113px;
  height: 36px;
  background-color: #585edb;
  color: white;
  border-radius: 25px;
  border: 1px solid #585edb;
  z-index: 200;
}
.pr_edit_img {
  margin-right: 8px;
}
.pr_group_img {
  position: absolute;
  right: 0px;
  z-index: 1;
}
.pr_form_preview {
  font-family: var(--font-poppins);
  margin: 24px;
}
.pr_form_preview .form-select {
  background-color: #fbfbfb;
}
.pr_form_text .form-label {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #091e42;
}
.pr_or {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: 24px;
}
.pr_form_p {
  color: #091e42;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}
.pr_form_btns {
  display: flex;
  float: right;
  gap: 16px;
  margin: 48px 24px;
}
.pr_form_save_btn {
  width: 166px;
  height: 36px;
  background-color: white;
  border-radius: 25px;
  border: 1px solid #585edb;
  color: #585edb;
}
.pr_form_next_btn,
.pr_form_submit_btn {
  width: 95px;
  height: 36px;
  border-radius: 25px;
  background-color: #585edb;
  border: 1px solid #585edb;
  color: white;
}
.pr_form_submit_btn {
  width: 140px;
}
.custom_modal .modal-dialog {
  font-family: var(--font-poppins);
  width: 618px !important;
  height: 406px;
  max-width: 700px;
}
.submit_modal_img {
  margin: auto;
}
.submit_modal_head {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  line-height: 37.5px;
  color: #091e42;
}
.submit_modal_content {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: #7a8699;
  text-align: center;
}
.submit_cancel_btn {
  width: 117px;
  border-radius: 25px;
  background-color: white;
  border: 1px solid #585edb;
  color: #585edb;
}
.submit_save_btn {
  width: 117px;
  background-color: #585edb !important;
  border: 1px solid #585edb;
  border-radius: 25px;
}
@media screen and (max-width: 1300px) {
  .pr_menu > div {
    gap: 8px;
    padding: 1em 1.5em;
  }
  .pr_text {
    width: 6.7vw;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }
  .my_press {
    gap: 28.5rem;
    justify-content: space-between;
  }
  .my_press_text {
    font-size: 16px;
  }
  .pr_tables_head > tr > th {
    font-size: 12px;
  }
  .pr_tables_body > tr > td {
    font-size: 12px;
  }
  .pr_pagination {
    width: 58%;
    padding: 0px 100px;
  }
}

@media screen and (min-width: 320px) and (max-width: 600px) {
  .pr_menu {
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 12px;
  }
  .pr_menu > div {
    justify-content: center;
    width: 45%;
    height: 100px;
    padding: 8px 20px;
    gap: 4px;
  }
  .pr_menu > div > img {
    width: 2rem;
    height: 2rem;
  }
  .pr_num {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }
  .pr_text {
    width: 8vw;
    font-size: 10px;
    font-weight: 500;
    line-height: 21px;
  }
  .my_press {
    flex-direction: column;
    gap: 8px;
    margin-top: 30px;
  }
  .my_press_btns {
    justify-content: space-between;
    gap: 8px;
  }
  .my_press_text {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    padding: 0px;
    text-align: center;
  }
  .pr_tables_head > tr > th {
    font-size: 10px;
  }
  .my_view_btn {
    width: 80px;
    height: 25px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
  .my_add_btn {
    width: 80px;
    height: 25px;
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
  }
  .my_add_btn > img {
    width: 9px;
    height: 9px;
  }
  .pr_next_img {
    width: 72px;
    height: 32px;
  }
  .modal-content {
    padding: 12px;
  }
  .modal-body {
    gap: 20px;
  }
  .pr_modal_head {
    font-size: 20px;
  }
  .pr_modal_note {
    font-size: 18px;
    line-height: 20px;
  }
  .pr_modal_content {
    font-size: 14px;
    line-height: 20px;
  }
  .pr_modal_date {
    font-size: 10px;
  }
  .pr_modal_btn {
    margin-top: 5px;
  }
  .modal_cancel_btn {
    width: 90px;
  }
  .modal_save_btn {
    width: 150px;
  }

  .custom_col_submit {
    flex-basis: 100%;
    max-width: 100%;
  }
  .submit_form_control {
    font-size: 14px;
  }
  .submit_form_select {
    font-size: 12px;
  }

  .pr_form_save_btn {
    width: 120px;
    font-size: 14px;
  }

  .pr_form_submit_btn {
    width: 100px;
    font-size: 14px;
  }
  .custom_modal .modal-dialog {
    width: 95% !important;
    height: 200px;
  }
  .submit_done_img {
    width: 100px;
    height: 100px;
  }
  .submit_modal_head {
    font-size: 24px;
  }
  .submit_modal_content {
    font-size: 18px;
  }
  .submit_cancel_btn {
    width: 100px;
  }
  .submit_save_btn {
    width: 100px;
  }
}
