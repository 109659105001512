.gallery_layout {
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin: 24px;
}
.all_images {
  max-width: 675px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.list_view_btn,
.grid_view_btn {
  height: 36px;
  width: 36px;
  border-radius: 25px;
  border: 0px solid;
  margin-right: 8px;
  padding-bottom: 5px;
}
.grid_view_btn {
  margin-right: 16px;
}
.gallery_options_btns {
  display: flex;
  justify-content: space-between;
}
.upload_gallery_btn {
  width: auto;
  border-radius: 25px;
  border: 1px solid #585edb;
  background-color: #585edb;
  padding: 6px 16px;
  color: white;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.upload_gallery_img {
  margin-right: 6px;
}
.custom_modal_upload >.modal-dialog > .modal-content{
  padding: 24px 48px;
}
.gallery_tr > th {
  color: #091e42;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  padding: 24px 12px;
}

/* .gallery_table_td1 {
  width: 115px !important;
} */
.gallery_table_th2 {
  width: 285px;
}
.gallery_table_th3 {
  width: 285px;
}
.gallery_sr_checkbox {
  margin-right: 9px;
  width: 13px;
  height: 13px;
}
.gallery_table_body > tr > td {
  width: 171px;
  height: 71.6px;
  padding: 25px 0px;
  padding-left: 12px;
}
.gallery_table_body > tr:active {
  background-color: #eeeffb !important;
}
.gallery_table {
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 1px solid #bbbdf0;
  box-shadow: 0px 7px 20px 0px #eeeeee80;
  overflow-x: auto;
}
.image_description {
  padding: 24px;
  width: auto;
  background-color: #f6f7ff;
  border-radius: 16px;
  border: 1px solid #bbbdf0;
  box-shadow: 0px 7px 20px 0px #eeeeee80;
  overflow-x: auto;
}
.no_image {
  width: 23vw;
  font-size: 20px;
  font-weight: 500;

}
.image_name {
  width: 100%;
  padding-bottom: 32px;
  border-bottom: 1px solid #bbbdf0;
  font-family: var(--font-poppins);
  font-size: 25px;
  font-weight: 600;
  line-height: 37.5px;
  text-align: left;
}
.image_display {
  display: flex;
  margin: auto;
  margin-top: 32px;
  padding: 5px;
}
.multiple_images {
  position: relative;
}
.select_image_number {
  position: absolute;
  top: 48%;
  left: 45%;
  border-radius: 8px;
  font-family: var(--font-poppins);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  padding: 9px 11.5px;

  background-color: #ffffff66;
}
.image_detail {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  font-family: var(--font-poppins);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: #8993a4;
  border-bottom: 1px solid #bbbdf0;
  padding: 32px 0px;
}
.image_detail_output {
  font-family: var(--font-poppins);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: right;
  color: #091e42;
}
.gallery_details_btns {
  display: flex;
  gap: 7.5px;
  margin-top: 2rem;
}
.image_preview_btn {
  border-radius: 25px;
  padding: 6px 30px;
  border: 1px solid #585edb;
  background-color: white;
  color: #585edb;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.image_preview_btn:hover {
  background-color: rgb(228, 228, 228);
}
.image_url_btn {
  border-radius: 25px;
  padding: 6px 26px;
  border: 1px solid #585edb;
  background-color: #585edb;
  color: white;
  font-family: var(--font-poppins);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
}
.image_url_btn:hover {
  background-color: #242ddd;
}
.image_delete_btn {
  border-radius: 25px;
  padding: 6px 30px;
  border: 1px solid #ee8586 !important;
  background-color: #ee8586 !important;
  color: white;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.image_delete_btn:hover {
  background-color: #f0494c !important;
}
.image_delete_img {
  margin-right: 3px;
}
.grid_view_layout {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}
.grid_images {
  position: relative;
}
.grid_images > input {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 18px;
  height: 18px;
}
.modal_gallery_btn1 {
  width: 117px;
  border-radius: 25px;
  background-color: white;
  border: 1px solid #585edb;
  color: #585edb;
}
.modal_gallery_btn2 {
  width: 117px;
  background-color: #585edb !important;
  border: 1px solid #585edb;
  border-radius: 25px;
}
.modal_gallery_btn1:hover {
  background-color: #d6d6d6 !important;
}
.modal_gallery_btn2:hover {
  background-color: #181c74 !important;
}
.custom_modal_preview {
  width: 896px !important;
  max-width: 900px;
  margin: auto;
  right: 0px;
}
.custom_modal_preview > .modal-dialog {
  max-width: 648px;
}
@media screen and (max-width: 1300px) {
  .gallery_layout {
    gap: 32px;
  }
  .all_images {
    width: 60%;
    max-width: 570px;
  }
  .image_description {
    padding: 24px 16px;
  }

  .image_display {
    width: 25vw;
    height: 28%;
  }
  .image_preview_btn,
  .image_url_btn,
  .image_delete_btn {
    padding: 6px 20px;
    font-size: 11px;
  }
  .image_detail,
  .image_detail_output {
    grid-template-columns: repeat(2, 120px);
    font-size: 15px;
  }
  .grid_images > img {
    width: 13.5vw;
    height: 100%;
  }
}
@media screen and (min-width: 320px) and (max-width: 600px) {
  .all_images {
    width: 100%;
  }
  .gallery_layout,
  .gallery_options_btns {
    flex-direction: column;
  }
  .Payment_filter_btn,
  .upload_gallery_btn {
    float: right;
    font-size: 11px;
  }
  .upload_gallery_btn {
    margin-top: 15px;
  }
  .grid_view_layout {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  .grid_images > img {
    width: 100%;
    height: 100%;
  }
  .image_detail {
    grid-template-columns: repeat(2, 160px);
  }
  .image_display {
    width: 50vw;
    height: 28%;
  }
  .image_detail_output {
    width: 100%;
    text-align: left;
  }
  /* .gallery_details_btns {
    flex-direction: column;
    justify-items: center;
  } */
  .select_image_number {
    top: 35%;
    left: 40%;
    font-size: 16px;
    padding: 6px;
  }
  .gallery_preview_img{
    width: 310px;
    height: 200px;
  }
  .custom_modal_preview {
    width: 350px !important;
  }
}
