.add_new_staff .nav-tabs {
  border-width: 0;
}
.add_new_staff .nav-tabs .nav-link.active {
  color: #595fde !important;
  border-bottom: 3px solid #595fde !important;
}
.add_new_staff .nav-tabs .nav-link {
  font-size: 20px;
  color: #cdcdcd;
  border: none !important;
}

.btn-none {
  background-color: #595fde !important;
  border: 1px solid #595fde;
  color: white;
  border-radius: 4px;
  padding: 8px 15px;
  margin-left: 15px;
  box-shadow: 3px 2px 18px 0 rgba(0, 0, 0, 0.4);
}
/* .upload{
    padding-top: 20px;
} */

.file-input-container {
  display: flex;
  align-items: center;
  margin-top: -2px;
}

.file-input-box {
  border: 1px solid #ced4da;
  padding: 18px;
  flex: 1;
  cursor: pointer;
  border-radius: 4px;
}
.add_new_staff {
  padding-bottom: 60px;
}

.btn-2 {
  border: none;
  background-color: none !important ;
}
.addnew {
  width: 182px;
  padding: 13px 22px;
  border-radius: 22px;
  border: 1px solid rgba(89, 95, 222, 0.1);
  background-color: rgba(89, 95, 222, 0.1);
  color: #595fde !important;
  font-size: 11px;
  font-weight: 800;
  text-align: center;
  color: #0f0f0f;
}
.network_table_text {
  display: inline;
  color: #cdcdcd;
  cursor: pointer;
}
.network_table_text:hover {
  color: #0f0f0f;
}
.apply {
  background-color: #595fde !important;
  box-shadow: 3px 2px 18px 0 rgba(0, 0, 0, 0.4);
}
.clear {
  box-shadow: 3px 2px 18px 0 rgba(0, 0, 0, 0.4);
}
.list_status_pub {
  background-color: rgb(114 213 114);
  border: 1px solid rgb(114 213 114);
  padding: 6px 12px;
  border-radius: 20px;
  color: white;
}
.list_status_inactive_pub {
  background-color: rgb(233 88 88);
  border: 1px solid rgb(233 88 88);
  padding: 6px 12px;
  border-radius: 20px;
  color: white;
}

@media screen and (max-width: 500px) {
  .addnew {
    width: 132px !important;
    padding: 7px !important;
  }
  .pub_heading {
    font-size: 17px !important;
  }
}


.data-table-wrapper .rdt_TableHeadRow div {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
   
  display: flex;
  align-items: center;
   
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 15px;
  font-weight: 600;
  color:rgb(73, 73, 73)
   
  
  /* font-weight: 500 !important; */
}

.data-table-wrapper .rdt_TableCell {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  /* font-weight: 700 !important; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 8px;
   
  padding-left: 1rem !important
  
}