.pub-col{
    width: 80%;
}

.admin_publication_layout {
    font-size: 16px;
    background-color: #eeeeee;
    /* display: grid;
    grid-template-columns: 240px 1fr; */
  }
  @media screen and (min-width: 320px) and (max-width: 600px) {
    .admin_publication_layout {
      position: relative;
      margin-left: 88px;
    }
  }
  