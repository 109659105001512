/* .coupon_pages_layout {
  margin: 20px 36px;
}
.coupon_page_heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
}
.coupon_page_heading > h4 {
  font-family: var(--font-poppins);
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: #0f0f0f;
  margin-bottom: 0px;
}
.admin_coupon_btns {
  display: flex;
  gap: 48px;
  align-items: center;
}
.new_coupon_btn {
  width: 182px;
  padding: 13px 22px;
  border-radius: 22px;
  border: 1px solid rgba(89, 95, 222, 0.1);
  background-color: rgba(89, 95, 222, 0.1);
  color: #595fde !important;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  color: #0f0f0f;
}
.search_coupon {
  position: relative;
  width: 276px;
  height: 40px;
  border: 1px solid #595fde;
  border-radius: 25px;
  padding: 6px 12px;
}
.search_coupon input {
  border: none;
  outline: none;
  background: none;
  font-family: var(--font-poppins);
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #2e2e2e;
}
.search_coupon img {
  position: absolute;
  right: 17px;
  top: 8px;
} */
.add_coupon_detail {
  background-color: #fff;
  padding: 40px 20px;
}
.add_coupon_submit_btn {
  background-color: #595fde !important;
  border: 1px solid #595fde;
  color: white;
  margin-top: 40px;
  border-radius: 4px;
  padding: 10px 40px;
  box-shadow: 3px 2px 18px 0 rgba(0, 0, 0, 0.4);
}



/* ============================== */

.coupon_pages_layout {
  margin: 20px 36px;
}
.coupon_page_heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
}
.coupon_page_heading > h4 {
  font-family: var(--font-poppins);
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: #0f0f0f;
  margin-bottom: 0px;
}
.admin_coupon_btns {
  display: flex;
  gap: 48px;
  align-items: center;
}
.new_coupon_btn {
  background: #595fde;
  border: 1px solid #595fde;
  border-radius: 10px;
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.16));
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  padding: 6px 24px;
}
.search_coupon {
  position: relative;
  width: 276px;
  height: 40px;
  border: 1px solid #595fde;
  border-radius: 25px;
  padding: 6px 12px;
}
.search_coupon input {
  border: none;
  outline: none;
  background: none;
  font-family: var(--font-poppins);
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #2e2e2e;
}
.search_coupon img {
  position: absolute;
  right: 17px;
  top: 8px;
}
.search_coupon_add {
  position: relative;
  width: 366px;
  height: 40px;
  border: 1px solid #595fde;
  border-radius: 15px;
  padding: 6px 12px;
}
.search_coupon_add input {
  border: none;
  outline: none;
  background: none;
  font-family: var(--font-poppins);
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  width: 100%;
  color: #2e2e2e;
}
.search_coupon_add img {
  position: absolute;
  right: 17px;
  top: 8px;
}
.add_coupon_details {
  background-color: #fff;
  padding: 24px 36px;
}
.add_coupon_info {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 24px;
  width: 49%;
}
.add_coupon_info p {
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #091e42;
  margin-bottom: 0px;
  width: 33%;
}
.add_coupon_info input,
.add_coupon_info .form-control {
  width: 240px;
  height: 40px;
  padding: 6px 12px;
  border: none;
  outline: none;
  border-radius: 8px;
  background-color: #f5f6f7;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  color: #243757;
}
.new_coupon_btns {
  display: flex;
  justify-content: end;
  gap: 16px;
  margin-bottom: 16px;
}
.new_coupon_btns button {
  background-color: #595fde;
  border: 1px solid #595fde;
  border-radius: 5px;
  box-shadow: 5px 5px 10px 0px #00000029;
  padding: 2px 12px 4px;
}
.admin_coupon_table {
  overflow-x: auto;
}
.custom_coupon_modal_body > h5 {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  text-transform: none;
  padding: 13px 16px;
  font-size: 27px;
  line-height: normal;
  text-align: center;
}
.custom_coupon_modal_body > p {
  font-size: 16px;
  line-height: normal;
  text-align: center;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.64);
  overflow-wrap: break-word;
  margin-bottom: 24px;
  box-sizing: border-box;
}
.admin_filter_btn {
  display: flex;
  gap: 8px;
  align-items: center;
  background-color: #fff;
  border: 1px solid #585edb;
  border-radius: 25px;
  color: #585edb;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 6px 16px;
}
.filter_up_arrow {
  rotate: 180deg;
}
.coupon_filter_options {
  width: 30%;
}

.apply {
  background-color: #595fde !important;
  box-shadow: 3px 2px 18px 0 rgba(0, 0, 0, 0.4);
}
.clear {
  box-shadow: 3px 2px 18px 0 rgba(0, 0, 0, 0.4);
}
.coupon_add_search {
  display: flex;
  margin-bottom: 16px;
}
.coupon_add_search > label {
  color: rgb(73, 80, 87);
  font-size: 14px;
  font-weight: normal;
}
.coupon_add_search > label > input {
  width: 343px  ;
  height: 26px !important;
  padding: 18px;
  border: 1px solid rgb(206, 212, 218);
  border-radius: 22px;
  background-color: #e4e4e4;
  margin-left: 8px;
}
.list_status {
  background-color: rgb(114 213 114);
  border: 1px solid rgb(114 213 114);
  padding: 6px 12px;
  border-radius: 20px;
  color: white;
}
.list_status_inactive{
  background-color: rgb(233 88 88);
  border: 1px solid rgb(233 88 88);
  padding: 6px 12px;
  border-radius: 20px;
  color: white;
}
@media screen and (max-width: 700px) {
  .coupon_pages_layout {
    margin: 20px;
  }
  .coupon_page_heading {
    align-items: center;
    margin-bottom: 32px;
    gap: 16px;
    flex-wrap: wrap;
  }
  .coupon_page_heading > h4 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0px;
  }
  .admin_coupon_btns {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 12px;
    width: 100%;
  }
  .search_coupon {
    width: 90%;
    height: 40px;
    padding: 6px 12px;
  }
  .search_coupon img {
    position: absolute;
    right: 17px;
    top: 8px;
  }
  .search_coupon_add {
    width: 100%;
    height: 40px;
    padding: 10px;
  }
  .search_coupon_add input {
    width: 100%;
    font-size: 9px;
    line-height: 18px;
  }
  .new_coupon_btn {
    margin-top: 12px;
    font-size: 16px;
    line-height: 24px;
    padding: 6px 20px;
  }
  .add_coupon_details {
    padding: 16px;
  }
  .add_coupon_info {
    flex-direction: column;
    align-items: start;
    gap: 20px;
    margin-bottom: 10px;
  }
  .add_coupon_info p {
    font-family: var(--font-poppins);
    font-size: 16px;
  }
  .add_coupon_info input {
    width: 90%;
    height: 100%;
    padding: 6px 10px;
    font-size: 16px;
    line-height: 24px;
  }
  .new_coupon_btns {
    justify-content: space-evenly;
    margin-bottom: 24px;
    margin-top: 24px;
  }
  .new_coupon_btns button {
    padding: 4px 12px;
  }
  .coupon_action_btn {
    width: 8px;
    height: 18px;
  }
}


@media screen  and (max-width: 500px){

  .search_temp{
  
    width:243px !important;
  }
  
}
@media screen  and (max-width: 400px){

  .search_temp{
  
    width:153px !important;
  }
  
}


.data-table-wrapper .rdt_TableHeadRow div {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
   
  display: flex;
  align-items: center;
   
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 15px;
  font-weight: 600;
  color:rgb(73, 73, 73)
   
  
  /* font-weight: 500 !important; */
}

.data-table-wrapper .rdt_TableCell {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  /* font-weight: 700 !important; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 8px;
   
  padding-left: 1rem !important
  
}
