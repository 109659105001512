.title{
    border: 1px solid #e3e3e3;
  background-color: #595fde;
  color: #ffff;
  border-radius: 10px;
  margin-bottom: 15px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.25rem;

}

.chat_support{
    background-color: white;
  border-radius: 5px;
  box-shadow: 20px 20px 40px 0 rgba(0, 0, 0, 0.1);
  border: 0px;
  padding: 20px 20px 20px 20px;
  height:50%;
}
.chat_support1{
    background-color: white;
  border-radius: 5px;
  box-shadow: 20px 20px 40px 0 rgba(0, 0, 0, 0.1);
  border: 0px;
  padding: 20px 20px 20px 20px;
  
}

.mid_line{
    display: flex;
    justify-content:  space-around;
    color:#969595;
    font-weight: 550;
}

.status:hover {
    color: #555555;
    text-decoration: none;
  }

  .search_box{
    display:flex;
    justify-content: center;
  }

  .main{
    overflow-y: scroll;
    /* overflow-x: none; */
  }
   


  .search_chat {
    position: relative;
    width: 407px;
    height: 40px;
    border: 1px solid #969595;
    /* border-radius: 25px; */
    padding: 6px 12px;
  }
  .search_chat input {
    border: none;
    outline: none;
    background: none;
    font-family: var(--font-poppins);
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    color: #2e2e2e;
  }
  .search_chat img {
    position: absolute;
    right: 17px;
    top: 8px;
  }
  .search_chat_add {
    position: relative;
    width: 366px;
    height: 40px;
    border: 1px solid #595fde;
    border-radius: 15px;
    padding: 6px 12px;
  }
  .search_chat_add input {
    border: none;
    outline: none;
    background: none;
    font-family: var(--font-poppins);
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    width: 100%;
    color: #2e2e2e;
  }
  .search_chat_add img {
    position: absolute;
    right: 17px;
    top: 8px;
  }

  /* @media screen and (max-width: 1200px) {
    .search_chat{
      width:300px !important;
    }

  } */

  @media screen and (max-width: 1200px) {
     
    .search_chat {
      width: 90%;
      height: 40px;
      padding: 6px 12px;
    }
    .search_chat img {
      position: absolute;
      right: 17px;
      top: 8px;
    }
    .search_chat_add {
      width: 100%;
      height: 40px;
      padding: 10px;
    }
    .search_chat_add input {
      width: 100%;
      font-size: 9px;
      line-height: 18px;
    }
     
  }

   


  .content{
    font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: .5rem;

  }
  .content_box{
    background-color:rgb(222, 221, 221);
    color:#969595;
  }

  .box_styling{
    background-color: #595fde;
  color: #ffff;
  }

  .message{
    font-family: 'Nunito Sans', sans-serif;
    color: #323232;
    font-size: 14px;
    line-height: 20px;
}
  
.mark1{
    display: flex;
    justify-content: center;
}

.chat_client{
    display: flex;
    justify-content: right;
}


.chat {
    /* position: relative; */
    /* width: 325px; */
    height: 40px;
    border-color: #dddcdc;
    /* border-radius: 25px; */
    padding: 6px 12px;
    background-color: rgba(0, 0, 0, 0.02);
    display: flex;
  }
  .chat input {
    border: none;
    outline: none;
    background: none;
    font-family: var(--font-poppins);
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    color: #2e2e2e;
  }
  .chat img {
    /* position: absolute; */
    right: 17px;
    top: 8px;
  }
  .chat_add {
    position: relative;
    width: 366px;
    height: 40px;
    border: 1px solid #595fde;
    border-radius: 15px;
    padding: 6px 12px;
  }
  .chat input {
    border: none;
    outline: none;
    background: none;
    font-family: var(--font-poppins);
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    width: 100%;
    color: #2e2e2e;
  }
  .chat img {
    /* position: absolute; */
    right: 17px;
    top: 8px;
  }


  @media screen and (max-width: 700px) {
     
    .chat {
      width: 90%;
      height: 40px;
      padding: 6px 12px;
    }
    .chat img {
      /* position: absolute; */
      right: 17px;
      top: 8px;
    }
    .chat_add {
      width: 100%;
      height: 40px;
      padding: 10px;
    }
    .chat input {
      width: 100%;
      font-size: 9px;
      line-height: 18px;
    }
     
  }

  @media screen and (min-width: 320px) and (max-width: 600px) {
    .chat_support {
      position: none !important;
      margin-left: 0 !important;
      
    }
  }

  @media screen  and (max-width:575px){
    .chat_support{
      height:500px;
      margin-bottom: 40px;
    }
    
  }

  .stat{
    color:#969595;
  }

  .stat:focus{

    color:blue;
  
  }