.secondary_nav {
  display: flex;
  /* padding: 18px 24px; */
  padding: 0px 24px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f9fe;
  z-index: 0;
}
.tabs {
  display: flex;
  gap: 40px;
  font-size: 20px;
  font-weight: 400;
  color: #c2c9d1;
  line-height: 30px;
  text-align: left;
}
.path_one,
.path_two {
  padding: 18px 0px;
}
.path_active {
  color: #585edb;
  font-weight: 500;
  border-bottom: 2px solid #585edb;
}
.group_img {
  position: absolute;
  right: 0px;
  z-index: 1;
  top: 86px;
}
.secondary_nav button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 6px 30px 6px 31.75px;
  width: 111px;
  height: 36px;
  background: #585edb;
  color: white;
  border-radius: 25px;
  border: 1px solid #585edb;
  z-index: 3;
}
@media screen and (min-width: 320px) and (max-width: 600px) {
  .secondary_nav {
    height: 70px;
  }
  .tabs {
    gap: 16px;
    font-size: 10px;
    font-weight: 400;
    line-height: 30px;
  }
  .secondary_nav button {
    padding: 4px;
    gap: 4px;
    width: 25%;
    height: 25px;
    font-size: 10px;
    margin: 0px;
  }
  .group_img {
    display: none;
  }
}
