.support_layout {
  /* max-width: 1156px; */
  margin: 24px;
  display: flex;
  gap: 1.5rem;
  font-size: 16px;
  font-family: var(--font-poppins);
}
.ticket_list {
  width: 60%;
}
.ticket_list_btns {
  display: flex;
  gap: 20px;
  padding: 24px 0px;
}
.add_new_query_btn {
  padding: 6px 16px 6px 17px;
  border-radius: 25px;
  border: 1px solid #585edb;
  background-color: #585edb;
  color: white;
}
.Support_filter_btn {
  padding: 6px 16px;
  color: #585edb;
  border-radius: 25px;
  border: 1px solid #585edb;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  background-color: white;
}
.filter_accordian_text {
  padding-left: 200px;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #8b8ea5;
}
.filter_accordian_text_2 {
  padding-left: 142px;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #8b8ea5;
}
.ticket_list_table {
  border-radius: 8px;
  border: 1px solid #bbbdf0;
  box-shadow: 0px 7px 20px 0px #eeeeee80;
  overflow-x: auto;
}

.support_tr > th {
  background-color: #f9f9f9;
  padding: 25px 0px;
  padding-left: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}
.support_table_body > tr > td {
  font-size: 14px;
  padding: 25px 0px;
  padding-left: 16px;
  overflow-x: auto;
}
.support_table_footer {
  display: flex;
  gap: 8px;
}
.support_page_no {
  padding: 3px 10px 3px 16px;
  display: flex;
  gap: 4px;
  width: 29%;
}
.support_page_no > h4 {
  min-width: 90px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #98a1b0;
  padding: 0px;
  margin: 3px 0px;
}
.support_dropdown {
  padding: 0px !important;
}
.support_pagination {
  padding: 0px;
  width: 47%;
}
.support_pages {
  display: flex;
  padding: 0px 12px;
}
.support_pages > h4 {
  min-width: 70px;
  font-size: 12px;
  padding-top: 7px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #98a1b0;
}
.status_open {
  font-size: 14px;
  font-weight: 400;
  line-height: 20.02px;
  padding: 7px 10px;
  border-radius: 16px;
  background-color: #afe6af;
  color: #2a8729;
}
.status_close {
  font-size: 14px;
  font-weight: 400;
  line-height: 20.02px;
  text-align: left;

  padding: 7px 10px;
  border-radius: 16px;
  background-color: #f9adad;
  color: #a82727;
}
.ticket_chatbox {
  width: 40%;
  border: 1px solid #bbbdf0;
  border-radius: 16px;
  background-color: #f6f7ff;
  padding: 1.5rem;
}
.chatbox_title {
  display: flex;
  justify-content: space-between;
}
.chatbox_title > h4 {
  font-family: var(--font-poppins);
  font-size: 25px;
  font-weight: 600;
  line-height: 37.5px;
  text-align: left;
}
.chatbox_title > p {
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #585edb;
  padding: 6px;
}
.ticket_date,
.ticket_description {
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #505f79;
}
.ticket_description {
  font-weight: 500;
}
.chatbox_chat {
  border-width: 2px 0px;
  border-style: solid;
  border-color: #bbbdf0;
  box-sizing: border-box;
}
.chat_content {
  margin: 16px 0px;
  max-height: 606px;
  overflow-y: auto;
}
.admin_chat {
  float: left;
  width: 95%;
  padding: 8px;
  border-radius: 8px 8px 8px 0px;
  background-color: #e3e5f5;
  border: 1px solid #edeeff;
  color: #091e42;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.02px;
  margin-bottom: 1.5rem;
}
.user_chat {
  width: 95%;
  float: right;
  padding: 8px;
  border-radius: 8px 8px 0px 8px;
  background-color: white;
  border: 1px solid #bbbdf0;
  color: #091e42;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.02px;
  margin-bottom: 1.5rem;
  text-align: right;
}
.send_messages {
  width: 100%;
  display: flex;
  gap: 8px;
}
.support_chat_add {
  display: flex;
  gap: 8px;
  padding: 8px 3px;
}
.support_chat_input {
  width: 62%;
  padding: 8px 16px;
  border-radius: 20px;
  border: 1px solid #bbbdf0;
}
.support_chat_send {
  background-color: #585edb;
  border-radius: 25px;
  border: 1px solid #585edb;
  padding: 6px 25px;
}
.new_ticket_layout {
  margin: 24px;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #091e42;
}
.custom_col_new_ticket > select,
.custom_col_new_ticket > input,
.custom_col_new_ticket > textarea {
  background-color: #f5f6f7;
  color: #212529;
  font-weight: 300;
  font-size: 14px;
}
.ticket_account_buttons {
  display: flex;
  float: right;
  margin: 48px 24px;
}
.ticket_cancel_btn {
  background-color: white;
  color: #585edb;
  border-radius: 25px;
  border: 1px solid #585edb;
  padding: 6px 30px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.ticket_save_btn {
  background-color: #585edb;
  color: white;
  border-radius: 25px;
  border: 1px solid #585edb;
  padding: 6px 30px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 10px;
}

@media screen and (min-width: 320px) and (max-width: 600px) {
  .support_layout {
    margin: 12px;
    flex-direction: column;
  }
  .ticket_list {
    width: 100%;
  }
  .ticket_list_btns {
    flex-direction: column;
  }
  .add_new_query_btn,
  .Support_filter_btn {
    max-width: 160px;
    padding: 6px 8px;
    font-size: 14px;
  }
  .ticket_chatbox {
    width: 100%;
  }
  .chatbox_title > h4 {
    font-size: 15px;
  }
  .chatbox_title > p {
    font-size: 12px;
    padding: 6px 3px;
  }
  .ticket_date,
  .ticket_description {
    font-size: 12px;
  }
  .admin_chat,
  .user_chat {
    width: 95%;
    padding: 4px;
    font-size: 10px;
    margin-bottom: 1rem;
  }
  .send_messages {
    gap: 6px;
  }
  .support_chat_add {
    gap: 4px;
    padding: 8px 2px;
  }
  .support_attach_img,
  .support_add_img,
  .support_send_img {
    width: 14px;
    height: 14px;
  }
  .support_chat_input {
    width: 80%;
    padding: 4px 8px;
    font-size: 10px;
  }
  .support_chat_send {
    padding: 2px 6px;
  }
  .custom_col_new_ticket {
    width: 100%;
  }
  .ticket_account_buttons {
    margin: 24px 0px;
  }
  .ticket_cancel_btn,
  .ticket_save_btn {
    padding: 6px 5vw;
  }
}
