.analytics_pages_layout {
  margin: 20px 36px;
}
.analytics_page_heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
}
.analytics_page_heading > h4 {
  font-family: var(--font-poppins);
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: #0f0f0f;
  margin-bottom: 0px;
}
.analytics_page_heading > h4 > p {
  font-family: var(--font-poppins);
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  color: #0f0f0f;
  margin-bottom: 0px;
  margin-top: 8px;
}
.new_analytics_btn {
  background: #595fde;
  border: 1px solid #595fde;
  border-radius: 10px;
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.16));
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  padding: 6px 24px;
}
.analytics_datatable {
  border-radius: 10px !important;
}
.view_analytics_detail {
  background-color: white;
  border-radius: 5px;
  box-shadow: 20px 20px 40px 0 rgba(0, 0, 0, 0.1);
  border: 0px;
  padding: 43px 20px 20px 43px;
}
.date-picker-container {
  position: relative;
}

.date-picker-overlay {
  position: absolute;
  right: 0;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 5px;
}
.apply_date_btn {
  background: #595fde;
  border: 1px solid #595fde;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 5px;
  padding: 8px 20px;
  cursor: pointer;
  float: right;
}
.analytics_card {
  display: flex;
  justify-content: space-between;
}
.card_info {
  border-radius: 8px;
  box-shadow: 0px 8px 40px 0 rgb(243 246 249 / 80%);
  padding: 15px 20px;
   display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 23%;
  position: relative;  */
}
.card_detail {
  padding-top: 10px;
}
.card_detail h5 {
  font-size: 28px;
  font-weight: bold;
  color: #fff;
  line-height: 0.6;
  font-family: var(--font-poppins);
}
.card_detail span {
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  line-height: 1.6;
  font-family: var(--font-poppins);
}
.card_img {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card_img span {
  background-color: #f4f5f9;
  display: block;
  width: 60px;
  height: 60px;
  padding: 10px;
  margin-left: auto;
  border-radius: 50%;
  text-align: center;
  margin-bottom: 10px;
}
.card_img p {
  color: #fff;
  margin-bottom: 0px;
  font-size: 11px;
  font-weight: 600;
  font-family: var(--font-poppins);
}
.card_arrow {
  position: absolute;
  left: 50%;
  bottom: 15px;
  width: 12px;
  height: 12px;
}
.card_info:hover .card_arrow {
  bottom: 30px;
  transition: 0.3s;
}
.line-chart-container {
  border-radius: 8px;
  box-shadow: 0px 8px 40px 0 rgb(243 246 249 / 80%);
  background-color: #fff;
  margin: 30px 0px;
  padding: 30px;
}
.line-chart-container h5 {
  font-size: 25px;
  font-weight: 500;
  color: #192229;
  line-height: 1.2;
  font-family: var(--font-poppins);
}
.analytics_dropdown {
  padding-right: 12px;
  text-align: center;
}
.table_box {
  padding-bottom: 32px;
  border-bottom: 1px solid rgb(50, 50, 50);
  margin-bottom: 20px;
}
.table_box h4 {
  font-size: 16px;
  font-weight: 700;
}
.admin_analytics_table {
  border-radius: 12px;
  border: 1px solid rgb(222, 226, 230);
  margin-top: 20px;
}
.report_tab {
  border-bottom: 5px solid #595fde;
  padding-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.46;
  font-family: var(--font-poppins);
  color: #595fde;
  list-style: none;
  display: inline;
}


.card_info{
  height:130px;
  margin-bottom: 10px;
}

.info-btn{
  font-size: 18px;
  font-weight: bold;
  line-height: 1.46;
  color: #595fde;
  /* font-family: 'Poppins', sans-serif; */
  margin-right: 20px;
  
}

info-btn:hover{
  color: #595fde !important;

}

/* a.active {
  border-bottom: 5px solid #595fde;
  display: block;
  padding-bottom: 10px;
} */

.data-table-wrapper .rdt_TableHeadRow div {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
   
  display: flex;
  align-items: center;
   
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 15px;
  font-weight: 600;
  color:rgb(73, 73, 73)
   
  
  /* font-weight: 500 !important; */
}

.data-table-wrapper .rdt_TableCell {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  /* font-weight: 700 !important; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 8px;
   
  padding-left: 1rem !important
  
}
