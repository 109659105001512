.newsletter_pages_layout {
  margin: 20px 36px;
}
.newsletter_page_heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
}
.newsletter_page_heading > h4 {
  font-family: var(--font-poppins);
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: #0f0f0f;
  margin-bottom: 0px;
}
.newsletter_mail_btn {
  color: #fff;
  background-color: #595fde !important;
  font-size: 13px;
  border-radius: 13px;
  padding: 2px 8px 6px;
  cursor: pointer;
}

.data-table-wrapper .rdt_TableHeadRow div {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
   
  display: flex;
  align-items: center;
   
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 15px;
  font-weight: 600;
  color:rgb(73, 73, 73)
   
  
  /* font-weight: 500 !important; */
}

.data-table-wrapper .rdt_TableCell {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  /* font-weight: 700 !important; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 8px;
   
  padding-left: 1rem !important
  
}
