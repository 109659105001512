.notification_center {
  background-color: #eeeffb;
}
@media screen and (min-width: 320px) and (max-width: 600px) {
  .notification_center > .col {
    margin-left: 88px;
  }
  .notification_center {
    position: relative;
  }
  /* .dash_navbar > .title {
    font-size: 14px;
  } */
}
