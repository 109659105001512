.sub_enabled {
  width: 74px;
  padding: 3px 8px;
  border-radius: 16px;
  border: 1px solid #5886fe;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.02px;
  color: #5886fe;
}
.sub_disabled {
  width: 78px;
  padding: 3px 8px;
  border-radius: 16px;
  border: 1px solid #a3a3a3;
  color: #a3a3a3;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.02px;
}
.sub_edit_btn,
.sub_back_btn {
  position: relative;
  float: right;
  margin: 12px 24px;
  width: 127px;
  height: 36px;
  background-color: #585edb;
  color: white;
  border-radius: 25px;
  border: 1px solid #585edb;
  z-index: 2;
}
.sub_back_btn {
  width: 95px;
}
.sub_add_img {
  margin: 0px 8px;
}
.sub_back_img {
  margin: -2px 8px;
}
.sub_form_head {
  color: #091e42;
  font-size: 25px;
  font-weight: 400;
  line-height: 37.5px;
  text-align: left;
}
.sub_account_buttons {
  display: flex;
  float: right;
  margin: 48px 24px;
}
.sub_cancel_btn {
  background-color: white;
  color: #585edb;
  border-radius: 25px;
  border: 1px solid #585edb;
  width: 117px;
  padding: 6px 25px;
}
.sub_save_btn {
  background-color: #585edb;
  color: white;
  border-radius: 25px;
  border: 1px solid #585edb;
  width: 177px;
  padding: 6px 25px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 10px;
}

@media screen and (min-width: 320px) and (max-width: 600px) {
  .pr_group_img {
    position: absolute;
    right: 0px;
    z-index: 0;
  }
  .sub_add_img {
    width: 15px;
    height: 15px;
  }
  .custom_col_sub_account {
    flex-basis: 100%;
    max-width: 100%;
  }
  .sub_cancel_btn {
    width: 80px;
    font-size: 14px;
    padding: 5px 14px;
  }
  .sub_save_btn {
    width: 135px;
    font-size: 14px;
    padding: 5px 14px;
  }
}
