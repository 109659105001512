.admin_editorial_layout {
    margin: 20px 36px;
  }
  
  .page_heading_2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px !important;
  }
  .hSQKHD,
  .jDljLp {
    font-size: 16px !important;
  }
  .page_heading_2 > h4 {
    font-family: var(--font-poppins);
    font-size: 24px;
    font-weight: 500;
    line-height: 45px;
    text-align: left;
    color: #0f0f0f;
  }
  .btn-new {
    width: 182px;
    padding: 13px 22px;
    border-radius: 22px;
    border: 1px solid rgba(89, 95, 222, 0.1);
    background-color: rgba(89, 95, 222, 0.1);
    color: #595fde !important;
    font-size: 10px;
    font-weight: 650;
    text-align: center;
    color: #0f0f0f;
  }
  /* .btn.btn-new {
    background-color: #fff;
  } */
  .btn-export {
    padding: 8px;
    border: 3px solid #eeeeee;
    background-color: #eeeeee;
    border-radius: 25px;
  }
  .admin_editorial_entries {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .admin_editorial_entries > p {
    font-size: 14px;
    font-weight: 400;
    color: rgb(136, 136, 136);
    margin-bottom: 0px;
  }
  .custom_editorial_dropdown > button {
    border: 1px solid rgb(206, 212, 218);
    border-radius: 10px;
    background-color: transparent;
    color: rgb(73, 80, 87);
    padding: 4px 20px;
  }
  .custom_editorial_dropdown > .dropdown-toggle::after {
    display: none !important;
  }
  .editorial_search {
    display: flex;
  }
  .editorial_search > label {
    color: rgb(73, 80, 87);
    font-size: 14px;
    font-weight: normal;
  }
  .editorial_search > label > input {
    width: 343px !important;
    height: 26px !important;
    padding: 18px 39.4px 18px 38px;
    border: 1px solid rgb(206, 212, 218);
    border-radius: 22px;
    background-color: #e4e4e4;
    margin-left: 8px;
  }
  .admin_editorial_table {
    border-radius: 12px;
    border: 1px solid rgb(222, 226, 230);
    margin-top: 20px;
  }
  .editorial_table_head {
    height: 20px;
  }
  .editorial_table_head > tr {
    background-color: #f9f9f9;
  }
  .editorial_table_head > tr > th {
    background-color: #f9f9f9;
    padding: 0.5rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    position: relative;
  }
  .table_sort_img {
    position: absolute;
    right: 5px;
    top: 12px;
  }
  .editorial_table_body > tr > td {
    height: 50px;
    vertical-align: middle;
    font-family: var(--font-poppins);
    font-size: 14px;
    font-weight: 400;
    line-height: 23.24px;
  }
  .pagination_editorial {
    display: flex;
    gap: 20px;
  }
  .custom_previous {
    border: solid 1px #595fde;
    border-radius: 22px;
    color: #595fde;
    padding: 8px 12px;
    list-style: none;
  }
  .custom_next {
    border: solid 1px #595fde;
    color: #595fde;
    border-radius: 22px;
    padding: 8px 12px;
    list-style: none;
  }
  .custom_page {
    border: solid 1px #595fde;
    border-radius: 11px;
    color: #595fde;
    padding: 8px 12px;
    list-style: none;
  }
  .active_editorial {
    border: solid 1px #595fde;
    border-radius: 11px;
    color: white;
    background-color: #595fde;
    padding: 8px 12px;
    list-style: none;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 20px;
    overflow: hidden;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    background-color: #ccc;
    transition: background-color 0.4s, transform 0.4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.4s, background-color 0.4s;
  }
  
  .switch input:checked + .slider {
    background-color: #2138f3;
  }
  
  .switch input:checked + .slider:before {
    transform: translateX(15px);
  }
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
  .add_new_editorial {
    background-color: white;
    border-radius: 5px;
    box-shadow: 20px 20px 40px 0 rgba(0, 0, 0, 0.1);
    border: 0px;
    padding: 43px 20px 20px 43px;
  }
  .custom_col_add_editorial > .form-label {
    color: #595f6f;
    font-size: 16px;
    font-weight: 700;
  }
  .custom_col_add_editorial > .form-control {
    background-color: white;
    border-radius: 4px;
  }
  .add_editorial_submit_btn {
    background-color: #595fde !important;
    border: 1px solid #595fde;
    color: white;
    margin-top: 40px;
    border-radius: 4px;
    padding: 10px 40px;
    box-shadow: 3px 2px 18px 0 rgba(0, 0, 0, 0.4);
  }
  .personal_details {
    padding-bottom: 32px;
    border-bottom: 1px solid rgb(50, 50, 50);
    margin-bottom: 20px;
  }
  .personal_details > h5 {
    font-size: 16px;
    font-weight: 700;
  }
  .editorial_press_release {
    padding-top: 28px;
    border-top: 2px solid rgb(50, 50, 50);
  }
  .admin_filter_btn {
    display: flex;
    gap: 8px;
    align-items: center;
    background-color: #fff;
    border: 1px solid #585edb;
    border-radius: 25px;
    color: #585edb;
    font-family: var(--font-poppins);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 6px 16px;
  }
  .filter_up_arrow {
    rotate: 180deg;
  }
  .editorial_filter_options {
    width: 30%;
  }
  .editorial_export_btn {
    background-color: #585edb;
    border: 1px solid #585edb;
    border-radius: 25px;
    color: #fff;
    padding: 6px 32px;
    font-family: var(--font-poppins);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 24px;
  }
  .list_status {
    background-color: rgb(114 213 114);
    border: 1px solid rgb(114 213 114);
    padding: 6px 12px;
    border-radius: 20px;
    color: white;
  }
  .list_status_inactive{
    background-color: rgb(233 88 88);
    border: 1px solid rgb(233 88 88);
    padding: 6px 12px;
    border-radius: 20px;
    color: white;
  }
  @media screen and (max-width: 700px) {
    .admin_editorial_layout {
      margin: 16px;
    }
    .page_heading {
      align-items: center;
      margin-bottom: 32px;
    }
    .page_heading > h4 {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 0px;
    }
    .page_heading > button {
      width: 100px;
      padding: 6px 8px;
      border-radius: 22px;
      font-size: 9px;
      font-weight: 700;
      text-align: center;
      color: #0f0f0f;
    }
    .editorial_search {
      flex-direction: column;
    }
    .editorial_search > label {
      margin-bottom: 20px;
    }
    .editorial_search > label > input {
      width: 75% !important;
      height: 30px !important ;
      padding: 14px 20px;
    }
    .editorial_export_btn {
      width: 50%;
      padding: 4px 8px;
      font-size: 12px;
      margin-left: 0px;
    }
    .custom_col_add_editorial {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
  

.jodit-wysiwyg{
  height:"500px"
}

.button_grp{
  display: flex;
  justify-content: space-between;
}

 

.box_card{
  border-color:#f9f9f9;
  box-shadow: 0px 4px 10px -8px #0000008c;
  border-radius: 14px;
  
}

.box_card:hover{
  border-color: #363fef;
  
   
}

/* .box_card{
  width: 15.5% !important;
  background: white;
  margin-right: 1%;
  height: 80px;
   
  float: left;
  max-width: 24%;
  flex: unset !important;
  box-shadow: 0px 4px 10px -8px #0000008c;
  padding-top: 20px;
   
  padding: 0px;
} */


@media screen and (max-width: 614px){
  
  .heading_editorial{
    font-size: 14px !important;
  
  }
  .btn-new {
    width: 162px !important;
     padding: 10px 15px !important; 
    border-radius: 22px;
    border: 1px solid rgba(89, 95, 222, 0.1);
    background-color: rgba(89, 95, 222, 0.1);
    color: #595fde !important;
    font-size: 10px;
    font-weight: 650;
    text-align: center;
    color: #0f0f0f;
  }
  
}

@media screen and (max-width:454px) {

  .page_heading_2 {
    display:inline;
  }

  .heading_editorial{
    text-align: center !important;
  }
  .main_btn {
   display: flex !important;
   justify-content: center !important;
  }
   


  
}


 /* .rmdp-input{
  height:40px !important;
}  */

.rmdp-container input{
  height:40px !important;
  width:100%;
   
}

 .rmdp-container{
  width:100%;
} 


.data-table-wrapper .rdt_TableHeadRow div {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
   
  display: flex;
  align-items: center;
   
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 15px;
  font-weight: 600;
  color:rgb(73, 73, 73)
   
  
  /* font-weight: 500 !important; */
}

.data-table-wrapper .rdt_TableCell {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  /* font-weight: 700 !important; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 8px;
   
  padding-left: 1rem !important
  
}