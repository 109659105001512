.payments_pages_layout {
  margin: 20px 36px;
}
.payments_page_heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
}
.payments_page_heading > h4 {
  font-family: var(--font-poppins);
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: #0f0f0f;
  margin-bottom: 0px;
}
.admin_payments_btns {
  display: flex;
  gap: 24px;
  align-items: center;
}
.payment_add_btn {
  color: #fff;
  background-color: #595fde;
  border: 1px solid #595fde;
  font-size: 13px;
  border-radius: 8px;

  padding: 4px 12px;
}
.payments_history_page_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.payments_history_page_heading > h4 {
  font-family: var(--font-poppins);
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: #0f0f0f;
  margin-bottom: 0px;
}
.payment_export_btn {
  background-color: rgb(160 79 200);
  border-radius: 10px;
  padding: 12px 13px;
  width: 46px;
  margin-bottom: 24px;
}
.payment_export_img {
  rotate: 90deg;
}
.payment_history_table {
  padding-bottom: 30px;
  padding-top: 16px;
  border-bottom: 1px solid rgb(50, 50, 50);
}
.payment_history_table h4 {
  margin: 6px;
  padding: 6px;
  width: 18%;
  border-radius: 20px;
  font-size: 16px;
}

@media screen and (max-width:600px) {

  .payments_page_heading > h4{
    font-size: 15px !important;
  }
   
  
}


.data-table-wrapper .rdt_TableHeadRow div {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
   
  display: flex;
  align-items: center;
   
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 15px;
  font-weight: 600;
  color:rgb(73, 73, 73)
   
  
  /* font-weight: 500 !important; */
}

.data-table-wrapper .rdt_TableCell {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  /* font-weight: 700 !important; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 8px;
   
  padding-left: 1rem !important
  
}
