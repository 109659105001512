.profile_container {
  height: 174px;
  background-color: white;
  position: relative;
  margin: 24px;
}
.blank_photo {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, 0);
}
.rectangle_one {
  height: 110px;
  background-color: #fbfbfb;
}
.content_container {
  background-color: white;
  margin: 40px 24px 0px 24px;
}
.form-control {
  background-color: #fbfbfb;
  border-radius: 8px;
}
.col-6 {
  margin-bottom: 15px;
}
.edit_container {
  width: 100%;
  height: 202px;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.edit_container h5 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 8px;
}
.upload {
  display: flex;
}
.browse_image {
  display: flex;
  flex-direction: row;
  margin: 32.5px 55px;
  border: 1px solid #c2c7d0;
}
.add_picture {
  margin: 18.5px 33px 18.5px 21px;
  max-width: 100%;
}
.browse_image h6 {
  width: 350px;
  margin: 29.5px 0px;
  font-size: 13px;
  font-weight: 600;
  line-height: 21px;
  color: #98a1b0;
}
.light_text {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.24px;
  color: #98a1b0;
}
.browse_btn {
  width: 118px;
  height: 36px;
  padding: 6px 30px;
  margin: 34.5px 29px 34.5px 3em;
  border: 1px solid #585edb;
  border-radius: 25px;
  background-color: white;
  color: #585edb;
  text-align: center;
}
.change_buttons {
  display: flex;
  justify-content: flex-end;
  margin: 5px 24px;
}
.cancel_btn {
  background-color: white;
  color: #585edb;
  border-radius: 25px;
  border: 1px solid #585edb;
  padding: 6px 25px 6px 25px;
}
.save_btn {
  background-color: #585edb;
  color: white;
  border-radius: 25px;
  border: 1px solid #585edb;
  padding: 6px 25px 6px 25px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 16px;
}
.rectangle_two {
  height: 110px;
  background-image: url("../../../public/ProfilePicture.svg");
  background-size: cover;
  background-position: center;
  filter: blur(5px);
  -webkit-filter: blur(5px);
}

@media screen and (min-width: 320px) and (max-width: 600px) {
  .profile_container {
    height: 100px;
    margin: 0px;
    margin-bottom: 25px;
  }
  .blank_photo {
    width: 70px;
    height: 70px;
  }
  .rectangle_one {
    height: 65px;
    background-color: #fbfbfb;
  }
  .content_container {
    margin: 20px 12px 0px 12px;
  }
  .custom-col {
    flex-basis: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    font-size: 14px;
  }
  .form-control {
    height: 35px;
    font-size: 10px;
  }
  .edit_container {
    width: 100%;
    height: 170px;
  }
  .edit_container h5 {
    text-align: center;
    font-size: 14px;
  }
  .profile_picture {
    display: block;
    width: 60px;
    height: 60px;

    margin: auto;
  }
  .upload {
    display: block;
    width: 100%;
  }
  .browse_image {
    margin: 15px 0px;
  }
  .add_picture {
    width: 20%;
    margin: 0px 2px;
  }
  .browse_image h6 {
    margin: 12px;
    margin-top: 15px;
    width: 60%;
    font-size: 8px;
    font-weight: 500;
    line-height: 18px;
  }
  .light_text {
    font-size: 6px;
    font-weight: 300;
    line-height: 18px;
  }
  .browse_btn {
    width: 20%;
    height: 30px;
    padding: 3px 10px;
    margin: 20px 2px;
    font-size: 8px;
    text-align: center;
  }
  .change_buttons {
    margin: 5px 10px;
  }
  .cancel_btn,
  .save_btn {
    width: 39%;
    height: 30px;
    padding: 2px 5px;
    font-size: 10px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 16px;
    margin-top: 10px;
  }
  .rectangle_two {
    height: 70px;
  }
}
