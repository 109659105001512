.user_manage_layout {
  margin: 40px 36px;
}
.page_heading_user {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.page_heading_user > h4 {
  font-family: var(--font-poppins);
  font-size: 24px;
  font-weight: 500;
  line-height: 45px;
  text-align: left;
  color: #0f0f0f;
}
.refresh_btn {
  width: 122px;
  padding: 1px 6px;
  height: 56px;
  border-radius: 22px;
  border: 1px solid rgba(89, 95, 222, 0.1);
  background-color: rgba(89, 95, 222, 0.1);
  color: #595fde !important;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #0f0f0f;
}
.total_users_details {
  display: flex;
  justify-content: space-evenly;
}
.user_count {
  /* width: 100%; */
  background: white;
  /* margin-right: 7%; */
  height: 120px;
  border-radius: 14px;
  /* position: relative; */
  padding: 24px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}
.user_count > h4 {
  font-size: 22px;
  font-weight: 600;
  font-family: var(--font-poppins);
  line-height: 1.53;
  color: #2e2e2e;
  text-align: left;
}
.user_count > p {
  font-size: 14px;
  font-weight: 600;
  color: #2e2e2e;
  line-height: 1.5;
  letter-spacing: normal;
}
.user_count > span > img {
  position: absolute;
  right: 24px;
  top: 28px;
}
.add_new_user {
  /* width: 24%; */
  background: white;
  /* margin-right: 7%; */
  height: 120px;
  border-radius: 14px;
  position: relative;
  padding: 24px 30px;
  display:flex;
  align-items: center;
  justify-content: space-between;
  
}
.add_new_user > h4 {
  font-size: 22px;
  font-weight: 600;
  font-family: var(--font-poppins);
  line-height: 1.53;
  color: #2e2e2e;
  text-align: left;
}
.add_new_user > p {
  font-size: 14px;
  font-weight: 600;
  color: #2e2e2e;
  line-height: 1.5;
  letter-spacing: normal;
   
}
.add_new_user > span > img {
  position: absolute;
  right: 24px;
  top: 30px;
}
.user_information_box {
  /* width: 38%; */
  height: 120px;
  background: #282c4b;
  color: white;
  border-radius: 14px;
  padding: 15px 35px;
}
.user_information_box > h4 {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}
.user_information_box > p {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 4px;
}
.user_list_table {
  border-radius: 12px;
  border: 1px solid rgb(222, 226, 230);
  margin-top: 20px;
}
.user_list_table > table {
  overflow: auto;
}
.user_list_table_head {
  height: 20px;
}
.user_list_table_head > tr {
  background-color: #f9f9f9;
}
.user_list_table_head > tr > th {
  background-color: #f9f9f9;
  padding: 0.5rem;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  position: relative;
  width: 42px;
}
.user_list_table_head > tr > th:nth-child(3) {
  width: 197px;
}
.user_table_sort_img {
  position: absolute;
  right: 3px;
  bottom: 11px;
}
.user_list_table_body > tr > td {
  height: 61px;
  vertical-align: middle;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 400;
  line-height: 23.24px;
}
.pagination_user {
  display: flex;
  gap: 20px;
}
.custom_previous_user {
  border: solid 1px #595fde;
  border-radius: 22px;
  color: #595fde;
  padding: 8px 12px;
  list-style: none;
}
.custom_next_user {
  border: solid 1px #595fde;
  color: #595fde;
  border-radius: 22px;
  padding: 8px 12px;
  list-style: none;
}
.custom_page_user {
  border: solid 1px #595fde;
  border-radius: 11px;
  color: #595fde;
  padding: 8px 12px;
  list-style: none;
}
.active_user {
  border: solid 1px #595fde;
  border-radius: 11px;
  color: white;
  background-color: #595fde;
  padding: 8px 12px;
  list-style: none;
}
/* .rdt_TableCell {
  padding-top: 4px; 
  padding-bottom: 4px;
  line-height: 1.5; 
} */


.add_user_main{
  color: #323232;
  background-color:#f6f6f6;;
  
}
.user-image{
  background-image:url(../../../../public/build/user-icon.svg);
  height: 80px;
  width: 80px;
}
.adduser-image{
  background-image:url(../../../../public/build/add-user-icon.svg);
  height: 60px;
  width: 60px;
  background-repeat: no-repeat;
}
.thumb{
  background-image:url(../../../../public/build/thumb.svg);
  height: 30px;
  width: 30px;
}
.pr-history{
  background-image:url(../../../../public/build/pr-history.svg);
  height: 20px;
  width: 20px;
}
.downthumb{
  background-image:url(../../../../public/build/down-thumb.svg);
  height: 30px;
  width: 30px;
}
.backbutton{
  background-image: url(../../../../public/build/backbutton.svg);
  height: 50px;
  width: 50px;
}

@media screen and (min-width: 340px) and (max-width: 620px)  {
  .refresh_btn{
    width:100px !important;
    height:40px !important;
     
  }

  .page_heading_user > h4{
    font-size: 15px !important;
    line-height: center  !important;


  }
 
}
@media screen and (max-width: 359px) {
  .refresh_btn{
    width:80px !important;
    height:32px !important;
    font-size: 10px !important;
    
    
     
  }

  .page_heading_user > h4{
    font-size: 12px !important;
     
     


  }
  .page_heading_user {
    display: flex !important;
    align-items: center !important;
  }
 
}

.data-table-wrapper .rdt_TableHeadRow div {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
   
  display: flex;
  align-items: center;
   
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 15px;
  font-weight: 600;
  color:rgb(73, 73, 73)
   
  
  /* font-weight: 500 !important; */
}

.data-table-wrapper .rdt_TableCell {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  /* font-weight: 700 !important; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 8px;
  
}
