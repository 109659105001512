.affiliate_pages_layout {
  margin: 20px 36px;
}
.affiliate_page_heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
}
.affiliate_page_heading > h4 {
  font-family: var(--font-poppins);
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: #0f0f0f;
  margin-bottom: 0px;
}
.affiliate_page_heading > h4 > p {
  font-family: var(--font-poppins);
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  color: #0f0f0f;
  margin-bottom: 0px;
  margin-top: 8px;
}
.new_affiliate_btn {
  background: #595fde;
  border: 1px solid #595fde;
  border-radius: 10px;
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.16));
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  padding: 6px 15px;
}
.view_btn {
  background: #595fde;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 5px;
  padding: 4px 20px;
  cursor: pointer;
}
.approved_btn {
  color: #5dcc5c;
  background: #ecf9eb;
  padding: 5px 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  border-radius: 16px;
  text-align: center;
}
.disapproved_btn {
  color: #cc5c5c;
  background: #f9ebeb;
  padding: 5px 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  border-radius: 16px;
  text-align: center;
}
.application_datatable {
  border-radius: 10px !important;
}
.view_application_detail {
  background-color: white;
  border-radius: 5px;
  box-shadow: 20px 20px 40px 0 rgba(0, 0, 0, 0.1);
  border: 0px;
  padding: 43px 20px 20px 43px;
}
.view_action_btn {
  background-color: #595fde !important;
  border: 1px solid #595fde;
  color: white;
  margin-top: 40px;
  border-radius: 4px;
  padding: 8px 44px;
  box-shadow: 3px 2px 18px 0 rgba(0, 0, 0, 0.4);
}
.view_modal_body {
  gap: 12px;
  padding: 8px;
}
.view_modal_title {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  font-size: 27px;
  line-height: normal;
  text-align: center;
}
.view_modal_text {
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.64);
  text-align: center;
}
.modal_warning {
  width: 80px;
  height: 80px;
  border: 4px solid #f8bb86;
  border-radius: 50%;
  position: relative;
  box-sizing: content-box;
  margin: auto;
}
.warning_line {
  position: absolute;
  left: 50%;
  background-color: #f8bb86;
  width: 5px;
  height: 47px;
  top: 10px;
  border-radius: 2px;
  margin-left: -2px;
}
.warning_dot {
  position: absolute;
  left: 50%;
  background-color: #f8bb86;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: -4px;
  bottom: -11px;
}
.view_users_categories {
  display: flex;
  gap: 16px;
}
.view_users_categories p {
  color: #c2c9d1;
  font-family: sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 8px 16px;
  margin: 0;
}
.view_users_categories p:hover {
  color: #585edb;
}
.active_category {
  color: #585edb !important;
  border-bottom: 2px solid #585edb;
}
.view_user_cards {
  display: flex;
  align-items: center;
}
.view_card_one {
  background: #ffffff;
  border: 1px solid #bbbdf0;
  box-shadow: 0px 7px 20px rgba(238, 238, 238, 0.501967);
  border-radius: 12px;
  padding: 60px 20px;
}
.view_card_one p {
  margin: 0;
}
.view_card_two,
.view_card_three,
.view_card_four,
.view_card_five {
  background: #ffffff;
  border: 1px solid #bbbdf0;
  box-shadow: 0px 7px 20px rgba(238, 238, 238, 0.501967);
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.view_profile_title {
  color: #091e42;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 25px;
}
.success_text {
  color: #28a745 !important;
  font-weight: 600;
}
.wallet_balance {
  /* width: 20%; */
  border: 1px solid #bbbdf0;
  border-radius: 12px;
  box-shadow: 0px 7px 20px 0px #eeeeee80;
  display: flex;
  flex-direction: column;
  /* gap: 16px; */
  /* padding: 16px; */
}
.wallet_balance h4 {
  font-family: var(--font-poppins);
  font-size: 36px;
  font-weight: 600;
  line-height: 54px;
  color: #091e42;
}
.wallet_balance p {
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #091e42;
}
.wallet_withdraw {
  /* width: 80%; */
  border: 1px solid #bbbdf0;
  border-radius: 12px;
  box-shadow: 0px 7px 20px 0px #eeeeee80;
  padding: 17.5px 24px;
  display: flex;
  flex-direction: column;
  /* gap: 8px; */
}
.wallet_withdraw h4 {
  font-family: var(--font-poppins);
  font-size: 25px;
  font-weight: 600;
  line-height: 27.5px;
  color: #091e42;
}
.wallet_withdraw p {
  font-family: var(--font-poppins);
  font-size: 10px;
  font-weight: 400;
  line-height: 24px;
  color: #98a1b0;
  margin-bottom: 4px;
}
.withdraw_input {
  border-radius: 8px;
  background-color: #fafbfb;
  border: 1px solid #fafbfb;
  padding: 7px 16px;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 400;
  line-height: 23.24px;
  color: color-mix(in srgb, #c2c9d1, #000000 50%);
}
.withdraw_btn {
  float: right;
  padding: 2px 30px;
  background-color: #585edb;
  border: 1px solid #585edb;
  border-radius: 25px;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: white;
}
.wallet_withdraw_title {
  font-family: var(--font-poppins);
  font-size: 25px;
  font-weight: 600;
  line-height: 37.5px;
  color: #091e42;
  margin-bottom: 24px;
}
.wallet_withdraw_or {
  display: flex;
  justify-content: center;
  font-family: var(--font-poppins);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #7a8699;
  margin: 16px auto;
  padding: 8px 0px;
}
.wallet_withdraw_to {
  display: flex;
  gap: 16px;
}
.withdraw_account {
  display: flex;
  /* gap: 10px;
  width: 38%; */
  padding: 13.5px 16px;
  border: 1px solid #bbbdf0;
  border-radius: 8px;
}
.withdraw_account:hover {
  background-color: #f6f7ff;
}
.withdraw_account_name {
  font-family: var(--font-poppins);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: #091e42;
  margin-bottom: 8px;
}
.withdraw_account_type {
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 400;
  line-height: 23.24px;
  color: #7a8699;
  margin-bottom: 0px;
}
.wallet_cancel_btn {
  border-radius: 25px;
  background-color: white;
  border: 1px solid #585edb;
  color: #585edb;
  padding: 6px 30px;
}
.wallet_save_btn {
  border-radius: 25px;
  background-color: #585edb !important;
  border: 1px solid #585edb;
  padding: 6px 30px;
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.payment_category p {
  padding: 8px 16px;
  color: #c2c9d1;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.payment_category p:hover {
  color: #585edb;
}
.active_category_payment {
  color: #585edb !important;
  border-bottom: 4px solid #585edb;
}


@media screen and (max-width:600px) {

  .affiliate_page_heading > h4{
    font-size: 15px !important;
  }
   
  
}

/* .new_affiliate_btn{

} */


@media screen and (max-width: 400px) {

  .bank_heading{
    display: inline;
  }

  .bank1, .bank2{
    display: flex;
    justify-content: center;
  }

  .bank_btn{
    width:200px;
    margin-bottom: 20px;
     
  }
  
}

.data-table-wrapper .rdt_TableHeadRow div {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
   
  display: flex;
  align-items: center;
   
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 15px;
  font-weight: 600;
  color:rgb(73, 73, 73)
   
  
  /* font-weight: 500 !important; */
}

.data-table-wrapper .rdt_TableCell {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  /* font-weight: 700 !important; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 8px;
   
  padding-left: 1rem !important
  
}
