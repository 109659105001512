.profile_pages_layout {
    margin: 20px 36px;
  }
  .profile_page_heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    align-items: center;
  }
  .profile_page_heading > h4 {
    font-family: var(--font-poppins);
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
    color: #0f0f0f;
    margin-bottom: 0px;
  }
  .admin_profile_btns {
    display: flex;
    gap: 48px;
    align-items: center;
  }
  .new_profile_btn {
          width: 182px;
          padding: 13px 22px;
          border-radius: 22px;
          border: 1px solid rgba(89, 95, 222, 0.1);
          background-color: rgba(89, 95, 222, 0.1);
          color: #595fde !important;
          font-size: 13px;
          font-weight: 700;
          text-align: center;
          color: #0f0f0f;
  }
  .search_profile {
    position: relative;
    width: 276px;
    height: 40px;
    border: 1px solid #595fde;
    border-radius: 25px;
    padding: 6px 12px;
  }
  .search_profile input {
    border: none;
    outline: none;
    background: none;
    font-family: var(--font-poppins);
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    color: #2e2e2e;
  }
  .search_profile img {
    position: absolute;
    right: 17px;
    top: 8px;
  }
  .add_profile_details {
      background-color: #fff;
      padding: 40px 20px;
    }
    .add_profile_submit_btn {
      background-color: #595fde !important;
      border: 1px solid #595fde;
      color: white;
      margin-top: 40px;
      border-radius: 4px;
      padding: 10px 40px;
      box-shadow: 3px 2px 18px 0 rgba(0, 0, 0, 0.4);
    }
  
  
    @media screen and (max-width:600px) {
  
      .profile_page_heading > h4{
        font-size: 15px !important;
      }
       
      
    }
    @media screen and (max-width:470px) {
  
      .profile_page_heading {
       display: inline !important;
      }
  
      .comp{
        display: flex !important;
        justify-content: center !important;
        margin-bottom: 20px;
         
      }
       
      
    }
  
  
     
  
  