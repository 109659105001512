.sidebar {
  max-width: 240px;
  display: block;
  background-color: #595fde;
  /* height: fit-content; */
}

a {
  text-decoration: none;
}

.logo_wrapper {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.nav_items_container {
  margin-top: 35px;
  margin-bottom: 46px;
}
.item_subcategories {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.nav_item {
  width: 100%;
  list-style: none;
  display: flex;
  gap: 8px;
  align-items: center;
}

.nav_item:hover .sidebarname {
  color: #fff;
}
.nav_item_activeTab .sidebarname {
  color: #fff;
}

.nav_item a {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffffbf;
  padding-right: 10px !important;
}
.nav_item a:hover {
  background-color: rgba(52, 37, 37, 0.2);
}

.nav_item_active {
  background: #242cc0;
  box-shadow: 0px 20px 50px rgba(55, 69, 87, 0.1);
  width: 100%;
  list-style: none;
  padding: 8px 0px;
  display: flex;
  gap: 8px;
  align-items: center;
  border-top: none !important;
}

/* .nav_item_active:hover {
  background: #242cc0;
  box-shadow: 0px 20px 50px rgba(55, 69, 87, 0.1);
} */

.nav_item_active a {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
}

.help_box {
  padding: 16px 18px;
  background-color: #5d5fef;
  border-radius: 16px;
  background-image: url(../../../../public/build/img/background_learn_more.svg);
  background-size: cover;
  background-position: center;
}

.help_first {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.help_second {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: rgba(255, 255, 255, 0.8);
}

.learn_btn {
  display: flex;
  align-items: center;
  padding: 4px 10px;
  gap: 8px;
  background: #ffffff;
  border-radius: 5px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #585edb;
}

@media screen and (max-width: 998px) {
  .sidebar {
    max-width: fit-content;
    left: -88px;
    position: absolute;
    min-height: 215vh;
    height: 100%;
    z-index: 5;
    min-width: 70px;
  }

  .dashboard_container {
    position: relative;
    margin-left: 88px;
  }

  .help_box {
    width: 220px;
  }

  /* .nav_item {
      padding: 12px 12px;
    }
    .nav_item_active {
      padding: 12px 12px;
    } */
}

.sidebar-accordion {
  margin-top: 30px;
  height: 100%;
  max-height: 900px;
  overflow-y: auto;
  padding: 0;
  scrollbar-width: none;
}
.sidebar-accordion::-webkit-scrollbar {
  display: none;
}
.main col {
  overflow-y: auto;
}
.linee {
  margin-left: 46px;
  width: 1px;
  background-color: #fff;
}
.sidebar-accordion .accordion-body {
  padding: 6px 0px !important;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-left: 46px;
}
.sidebarname {
  font-size: 14px;
  margin-left: 12px;
  /* color: #fff; */
  color: rgba(255, 255, 255, 0.75);
}

.sidebar-accordion .accordion {
  background-color: transparent !important;
  border: none !important;
}
.sidebar-accordion .accordion-item {
  background-color: transparent !important;
  border: none !important;
}

.sidebar-accordion .accordion-button:focus {
  padding: 8px 16px;
  box-shadow: none;
  background-color: transparent !important;
  border: none !important;
}

/* .sidebar-accordion .accordion-button:focus .sidebarname{
  color: #fff;
} */

.sidebar-accordion .accordion-button {
  padding: 8px 16px;
  border-radius: 0px !important;
  background-color: transparent !important;
  border: none !important;
}
.sidebar-accordion .accordion-button::after {
  margin-right: -16px !important;
  background-image: url(../../../../public/build/rightarrow.svg);
  background-size: 8px;
  rotate: 0deg;
  transition: none;
}
.accordion-button:not(.collapsed)::after {
  background-size: 8px;
  background-image: url(../../../../public/build/downarrow.svg);
  rotate: 180deg;
  transition: none;
}
.sidebar-accordion .noicon .accordion-button::after {
  display: none;
}
.accordion-collapse .collapse .show {
  margin: 0px !important;
}

.payment {
  /* background-image: url(../../../../public/build/payment.svg); */
  height: 19px;
  width: 19px;
}
.newsletter {
  /* background-image: url(../../../../public/build/newsletter.svg); */
  height: 19px;
  width: 19px;
}
.affiliate {
  /* background-image: url(../../../../public/build/affiliate-center.svg); */
  height: 19px;
  width: 19px;
}
.analytics {
  /* background-image: url(../../../../public/build/analytics.svg); */
  height: 19px;
  width: 19px;
}
.invoice {
  /* background-image: url(../../../../public/build/invoice.svg); */
  height: 19px;
  width: 19px;
}
.login {
  /* background-image: url(../../../../public/build/login.svg); */
  height: 19px;
  width: 19px;
}
.logout {
  /* background-image: url(../../../../public/build/logout.svg); */
  height: 19px;
  width: 19px;
}
