.admin_newsletter_content {
    font-size: 16px;
    background-color: #EEEEEE;
    display: grid;
    grid-template-columns: 240px 1fr;
  }
  @media screen and (min-width: 320px) and (max-width: 600px) {
    .admin_newsletter_content {
      position: relative;
      margin-left: 88px;
    }
  }