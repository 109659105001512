.dashboard_container {
  background-color: #eeeffb;
  min-height: 100vh;
  flex-wrap: nowrap;
}

.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
}


.navbar .container {
  margin-left: 0px;
  margin-right: 0px;
}

.dash_navbar {
  display: flex;
  padding: 18px 24px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  color: #151d48;
}

.outline_btn {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: #ffffff;
  border: 1px solid #595fde;
  border-radius: 25px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #595fde;
}

.fill_btn {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: #595fde;
  border-radius: 25px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  justify-content: center;
}

.nav_btn_container {
  width: 100%;
  display: flex;
  /* gap: 44px; */
  justify-content: end;
}

.nav_btns {
  display: flex;
  gap: 19px;
}

.search_btn {
  width: 400px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 2px 32px 2px 14px;
  background: #f5f6f7;
  border-radius: 16px;
}

.search_btn input {
  border: none;
  outline: none;
  background: none;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #a3a3a3;
  width: 100%;
}
.navbar_btn {
  padding: 12px;
  border: 3px solid #eeeeee;
  background-color: #eeeeee;
  border-radius: 25px;
}
.nav_profile {
  display: flex;
  align-items: center;
  gap: 48px;
}
.admin_nav_btns {
  display: flex;
  gap: 16px;
  padding-right:26px;
  border-right: 2px solid #d7d7d7;
}
.nav_admin_detail {
  display: flex;
  flex-direction: column;
}
.nav_admin_detail > h5 {
  font-size: 14px;
  font-weight: 700;
  color: #2e2e2e;
  margin-bottom: 0px;
  margin-top: 7px;
}
.nav_admin_detail > p {
  font-size: 12px;
  font-weight: 400;
  color: #2e2e2e;
  text-align: right;
  margin-bottom: 0px;
  margin-top: 10px;
}
.admin_lang > button {
  background-color: white;
  padding: 17px;
  color: #2e2e2e;
  border-radius: 25px;
  border: 1px solid #eeeeee;
}
.left_main {
  padding: 24px;
}

.right_main {
  padding: 24px 40px 24px 16px;
}

.right_main_title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #091e42;
}

.left_main .package_info {
  display: flex;
  gap: 16px;
}

.left_main .package_info > div {
  background-color: #ffffff;
  padding: 16px;
  border: 1px solid #bbbdf0;
  box-shadow: 0px 7px 20px rgba(238, 238, 238, 0.501967);
  border-radius: 12px;
}

.articles_info {
  flex: 1;
  min-width: 172px;
}

.d_article_img {
  margin-bottom: 16px;
}

.d_article_num {
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  color: #091e42;
  margin-bottom: 16px;
}

.d_article_text {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #091e42;
}

.package_owned {
  flex: 1;
  min-width: 172px;
}

.pr_status_container {
  flex: 2;
  min-width: 357px;
}

.pr_text_wrapper {
  padding: 0px 16px;
}

.dash_expire_info {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.dash_expire_child {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  padding: 16px 0px 16px 10px;
  border: 1px solid #eeeffb;
  border-radius: 8px;
  min-width: 153px;
}

.dash_expire_title {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: rgba(152, 161, 176, 1);
}

.dash_expire_date {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #333333;
}

.dash_expire_time {
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #ee8586;
}

.dash_prstatus_text {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #091e42;
}

.dash_viewall {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #585edb;
}

.notification_info {
  background-color: #ffffff;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #bbbdf0;
  box-shadow: 0px 7px 20px rgba(238, 238, 238, 0.501967);
  border-radius: 12px;
}

.notification_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification_text {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #091e42;
}

.notification_viewall {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #595fde;
}

.notification_actions {
  display: flex;
  gap: 8px;
}

.color_box {
  min-width: 15px;
  height: 15px;
  background-color: #f3595a;
  border-radius: 5px;
  flex-shrink: 0;
}

.notification_action {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #091e42;
}

.hi {
  background-color: white !important;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

element {
  padding: 0px;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

.btn-primary {
  border: none;
}

.dropdown-toggle {
  padding: 0px;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  top: 30px !important;
}

.notification_action_desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #918fbf;
}

.notification_action_time {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.01em;
  color: #b6afcf;
}

.package_details_text {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
  align-items: center;
}

.dash_pack_text {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #091e42;
}

.dash_pack_view {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #505f79;
}

.lastpr_main_cont {
  margin-bottom: 24px;
}

.lastpr_text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3b3f80;
  margin-bottom: 7px;
}

.lastpr_time {
  font-weight: 400;
  font-size: 14px;
  line-height: 23.24px;
  color: #918fbf;
}

.table-responsive {
  background: #ffffff;
  border: 1px solid #bbbdf0;
  box-shadow: 0px 7px 20px rgba(238, 238, 238, 0.501967);
  border-radius: 8px !important;
}

.table_container tr {
  border-color: white;
  border-style: none !important;
}

.table_footer {
  display: flex;
  background-color: white;
  align-items: center;
  padding: 0px 16px 16px 16px;
}

.rows_per_page {
  flex: 1;
  text-align: start;
  display: flex;
  gap: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #98a1b0;
}

.rows_page {
  flex: 2;
  display: flex;
  justify-content: center;
}

.go_to_page {
  flex: 1;
  display: flex;
  justify-content: end;
  gap: 16px;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #98a1b0;
}

.go_to_page input {
  outline: none;
  border: none;
  border-bottom: 1px solid grey;
  width: 23px;
}

.dash_select {
  background: white;
  border: none;
}

.first_package_text {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.t_head_wrapper {
  background: #f9f9f9 !important;
}

.t_head_item {
  background-color: #f9f9f9 !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  color: #091e42;
  padding: 16px !important;
}

.custom_pagination {
  display: flex;
  gap: 2px;
}

.page-item {
  list-style: none;
}

.page-link {
  padding: 0px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 32px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #b3b9c4;
}

.submit_first_pr {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.t_item {
  padding: 16px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 166% !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.tableres {
  width: 100%;
}

.responsive_menu {
  position: absolute;
  top: 0px;
  height: 100%;
  right: 0px;
  width: 200px;
  background-color: #eeeffb;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.menu_item {
  padding: 16px;
  text-align: end;
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  color: #333333;
}

.menu_item:hover {
  background-color: white;
}

.res-profile {
  padding: 10px 16px;
}

@media screen and (max-width: 576px) {
  .title {
    font-size: 16px;
  }
  .responsive_menu {
    z-index: 5;
  }
}

@media screen and (max-width: 1440px) {
  .package_info {
    flex-wrap: wrap;
  }

  .dash_expire_info {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 992px) {
  .nav_btn_container {
    gap: 10px;
  }
}

@media screen and (max-width: 699px) {
  .pr_status_container {
    min-width: unset;
  }
  .dash_navbar {
    padding: 12px;
  }
 .search_btn_mb{
  display: flex;
  align-items: center;
 }
 .nav_profile {
  gap: 12px;
}
 .admin_nav_btns {
  gap: 8px;
  padding-right: 12px;
}
.navbar_btn {
  padding: 4px 6px
}
.nav_exit_img, .nav_bell_img, .nav_msg_img{
  width: 16px;
  height: 14px;
}
.nav_admin_detail{
  justify-content: center;
}
.nav_admin_detail > h5 {
  font-size: 9px;
  margin-top: 4px;
}
.nav_admin_detail > p {
  font-size: 9px;
}
.nav_profile_img{
  height: 70px;
}
}
