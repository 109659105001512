.form-body{
    border-radius: 5px;
  box-shadow: 20px 20px 40px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: 0px !important;
   padding:43px;
}

.back_img{
    vertical-align: middle;
  border-style: none;
  height:49px;
  width:46px;
}


.page_head{
    padding: 20px 0px;
  position: relative;
  /* margin: 0px 10px 0px 10px; */
  
}

.form-label{
     
        color: #595f6f;
        font-size: 16px;
        font-weight: 700;
}

.form-control_input {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    height:45px;
    font-weight: 400;
  }

  /* select.form-control_input1:not([multiple]) {
    height: calc(2.25rem + 2px);
  } */

  
   

  .submit-btn {
    background-color: #595fde !important;
    
    padding: 10px 40px;
    box-shadow: 3px 2px 18px 0 rgba(0, 0, 0, 0.4);
    
     
  }

  @media only screen and (max-width: 400px) {


    .head_1{
      font-size: medium!important;
    }
    .form-label{
      font-size:medium!important ;
    }

    .form-control {
      font-size: smaller!important;
    }






  }


  .backbutton{
    background-image:url(../../../../public/build/backbutton.svg);
    background-size: 40px 40px;
    width: 40px;
    height: 40px;
  }