.hamberger {

    color:rgb(26, 25, 25);
    width: 40px;
    height:40px;
    
  }
  
  .ham-main{
  
    display: flex;
  justify-content: left;
  /* padding-right: 20px;
  padding-top: 20px; */
  align-items: center;
   

  }

  @media screen and (max-width: 1000px) {

    .ham-main{
      display:box;
    }
  
  
  }

  @media screen and (width> 1000px)  {

    .ham-main{
      display:none;
    }
  
  
  }
  