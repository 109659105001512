.common_fill_btn {
  box-sizing: border-box;
  display: flex;
  padding: 8px 16px;
  background: #595fde;
  border: 1px solid #595fde;
  border-radius: 25px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: white;
}
.small_btn {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #ffffff;
}
.first_pack_btn {
  font-weight: 500;
  font-size: 21px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #ffffff;
}
