.report_pages_layout {
  margin: 20px 36px;
}
.report_page_heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
}
.report_page_heading > h4 {
  font-family: var(--font-poppins);
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: #0f0f0f;
  margin-bottom: 0px;
}
.admin_report_btns {
  display: flex;
  gap: 24px;
  align-items: center;
}

.report_create_btn {
  background: #595fde;
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.16));
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 6px 25px;
  text-align: center;
  border-radius: 10px;
  border: none;
}
.add_report_details {
  background-color: #fff;
  padding: 40px 20px;
}
.report_detail_text {
  color: #0f0f0f;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 30px;
}
.report_detail_text span {
  font-weight: 600;
}
.add_report_process {
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  margin-bottom: 32px;
}
.add_report_process li {
  list-style: none;
  display: flex;
  gap: 16px;
  align-items: center;
  color: #373c48;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}
.add_report_process li div {
  border: 2px solid rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  padding: 36px 28px;
}
.active_step div {
  background: #595fde;
  color: #fff;
  border: none;
}
.report_apply_btn {
  background: #595fde;
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.16));
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 8px 28px;
  text-align: center;
  border-radius: 4px;
  border: none;
  width: 100%;
}
.report_post_box {
  border-radius: 16px;
  border: 1px solid #bbbdf0;
  background: #f6f7ff;
  padding: 50px 40px;
  margin-bottom: 50px;
}
.report_post_btns{
  display: flex; 
  gap: 12px;
  width: 40%;
}
.report_add_search {
  display: flex;
  margin-bottom: 16px;
}
.report_add_search > label {
  color: rgb(73, 80, 87);
  font-size: 14px;
  font-weight: normal;
}
.report_add_search > label > input {
  width: 343px !important;
  height: 26px !important;
  padding: 18px;
  border: 1px solid rgb(206, 212, 218);
  border-radius: 22px;
  background-color: #e4e4e4;
  margin-left: 8px;
}
.new_report_btns {
  display: flex;
  justify-content: end;
  gap: 16px;
  margin-bottom: 16px;
}
.new_report_btns button {
  background-color: #595fde;
  border: 1px solid #595fde;
  border-radius: 5px;
  box-shadow: 5px 5px 10px 0px #00000029;
  padding: 2px 12px 4px;
}


.data-table-wrapper .rdt_TableHeadRow div {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
   
  display: flex;
  align-items: center;
   
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 15px;
  font-weight: 600;
  color:rgb(73, 73, 73)
   
  
  /* font-weight: 500 !important; */
}

.data-table-wrapper .rdt_TableCell {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  /* font-weight: 700 !important; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 8px;
   
  padding-left: 1rem !important
  
}