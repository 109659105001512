.admin_network_layout {
  margin: 20px 36px;
}
.network_page_heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
}
.network_page_heading > h4 {
  font-family: var(--font-poppins);
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: #0f0f0f;
  margin-bottom: 0px;
}
.admin_network_btns {
  display: flex;
  gap: 48px;
  align-items: center;
}
.new_network_btn {
  background: #595fde;
  border: 1px solid #595fde;
  border-radius: 10px;
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.16));
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  padding: 6px 24px;
}
.search_network {
  position: relative;
  width: 276px;
  height: 40px;
  border: 1px solid #595fde;
  border-radius: 25px;
  padding: 6px 12px;
}
.search_network input {
  border: none;
  outline: none;
  background: none;
  font-family: var(--font-poppins);
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #2e2e2e;
}
.search_network img {
  position: absolute;
  right: 17px;
  top: 8px;
}
.network_page_details {
  background-color: white;
  /* margin: 7px 0px 60px 8px; */
  padding: 28px 50px;
  box-shadow: 20px 20px 40px 0px #0000001a;
  position: relative;
}
.network_delete_btn {
  background-color: #595fde;
  padding: 8px 12px;
  border: 1px solid #595fde;
  border-radius: 5px;
}

.admin_network_table > table {
  border-radius: 10px;
}
.pagination_network {
  display: flex;
  gap: 20px;
}
.custom_previous_network {
  border: solid 1px #595fde;
  border-radius: 22px;
  color: #595fde;
  padding: 8px 12px;
  list-style: none;
}
.custom_next_network {
  border: solid 1px #595fde;
  color: #595fde;
  border-radius: 22px;
  padding: 8px 12px;
  list-style: none;
}
.custom_page_network {
  border: solid 1px #595fde;
  border-radius: 11px;
  color: #595fde;
  padding: 8px 12px;
  list-style: none;
}
.active_network_page {
  border: solid 1px #595fde;
  border-radius: 11px;
  color: white;
  background-color: #595fde;
  padding: 8px 12px;
  list-style: none;
}
.network_upload_btn {
  background-color: #595fde;
  border: 1px solid #595fde;
  border-radius: 5px;
  padding: 8px 12px;
}
.add_network_table {
  background-color: white;
  border-radius: 5px;
  box-shadow: 20px 20px 40px 0 rgba(0, 0, 0, 0.1);
  border: 0px;
  padding: 24px 20px 65px 30px;
}
.add_network_add_btn {
  background-color: #595fde !important;
  border: 1px solid #595fde;
  color: white;
  border-radius: 4px;
  padding: 10px 40px;
  box-shadow: 3px 2px 18px 0 rgba(0, 0, 0, 0.4);
  float: right;
}
.network_table_dwonload_text {
  margin-top: 8px;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #595fde;
  cursor: pointer;
}
.admin_network_filter_btn {
  display: flex;
  gap: 8px;
  align-items: center;
  background-color: #fff;
  border: 1px solid #585edb;
  border-radius: 25px;
  color: #585edb;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 6px 16px;
}
.filter_up_arrow {
  rotate: 180deg;
}
.network_filter_options {
  width: 300px;
}
.list_status_network {
  background-color: rgb(114 213 114);
  border: 1px solid rgb(114 213 114);
  padding: 6px 12px;
  border-radius: 20px;
  color: white;
}
.list_status_inactive_network {
  background-color: rgb(233 88 88);
  border: 1px solid rgb(233 88 88);
  padding: 6px 12px;
  border-radius: 20px;
  color: white;
}


@media screen and (max-width: 700px) {
  .template_pages_layout {
    margin: 20px;
  }
  .network_page_heading {
    align-items: center;
    margin-bottom: 32px;
    gap: 16px;
    flex-wrap: wrap;
  }
  .network_page_heading > h4 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0px;
  }
  .admin_network_btns {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 12px;
    width: 100%;
  }
  .search_network{
    width: 90%;
    height: 40px;
    padding: 6px 12px;
  }
  .search_networkimg {
    position: absolute;
    right: 17px;
    top: 8px;
  }
  .search_network_add {
    width: 100%;
    height: 40px;
    padding: 10px;
  }
  .search_network_add input {
    width: 100%;
    font-size: 9px;
    line-height: 18px;
  }
  .new_network_btn {
    margin-top: 12px;
    font-size: 16px;
    line-height: 24px;
    padding: 6px 20px;
  }
  .add_network_details {
    padding: 16px;
  }
  .add_network_info {
    flex-direction: column;
    align-items: start;
    gap: 20px;
    margin-bottom: 10px;
  }
  .add_network_info p {
    font-family: var(--font-poppins);
    font-size: 16px;
  }
  .add_network_info input {
    width: 90%;
    height: 100%;
    padding: 6px 10px;
    font-size: 16px;
    line-height: 24px;
  }
  .new_network_btns {
    justify-content: space-evenly;
    margin-bottom: 24px;
    margin-top: 24px;
  }
  .new_network_btns button {
    padding: 4px 12px;
  }
  .network_action_btn {
    width: 8px;
    height: 18px;
  }
}