.package_pages_layout {
  margin: 20px 36px;
}
.package_page_heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
}
.package_page_heading > h4 {
  font-family: var(--font-poppins);
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: #0f0f0f;
  margin-bottom: 0px;
}
.admin_package_btns {
  display: flex;
  gap: 24px;
  align-items: center;
}
.new_package_btn {
  padding: 13px 22px;
  border-radius: 22px;
  border: 1px solid rgba(89, 95, 222, 0.1);
  background-color: rgba(89, 95, 222, 0.1);
  color: #595fde !important;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  color: #0f0f0f;
}
.list_status_package {
  background-color: rgb(114 213 114);
  border: 1px solid rgb(114 213 114);
  padding: 6px 12px;
  border-radius: 20px;
  color: white;
}
.list_status_inactive_package {
  background-color: rgb(233 88 88);
  border: 1px solid rgb(233 88 88);
  padding: 6px 12px;
  border-radius: 20px;
  color: white;
}
.add_package_details {
  background-color: #fff;
  padding: 40px 20px;
}
.add_package_submit_btn {
  background-color: #595fde !important;
  border: 1px solid #595fde;
  color: white;
  margin-top: 40px;
  border-radius: 4px;
  padding: 10px 40px;
  box-shadow: 3px 2px 18px 0 rgba(0, 0, 0, 0.4);
}
.add_package_des_btn {
  background-color: #2e61f2 !important;
  border: 1px solid #2e61f2;
  color: white;
  margin-top: 4px;
  margin-bottom: 24px;
  border-radius: 4px;
  padding: 10px 40px;
  box-shadow: 3px 2px 18px 0 rgba(0, 0, 0, 0.4);
}
.show_table_delete_btn {
  padding: 10px;
  background-color: #ff5450;
  border: 1px solid #ff5450;
  color: white;
  border-radius: 4px;
  font-size: 15px;
}
.package_detail_status {
  background-color: #fff;
  color: #0f0f0f;
  border: 1px solid #ced4da;
  font-size: 16px;
  padding: 5px;
  margin-right: 24px;
}
.package_history_box {
  margin-top: 36px;
  padding-bottom: 36px;
  border-bottom: 1px solid rgb(50, 50, 50);
}
.package_history_text {
  margin: 6px;
  padding: 6px;
  border-radius: 20px;
  font-size: 16px;
}


@media screen and (max-width:650px) {

  .heading_pack{

    display: inline !important;
  }
   .pack1{
    font-size: 15px !important;
   }
   .pack1,.pack2{
    display: flex;
    justify-content: center;
     

  } 

  
   /* .new1{
    padding: 10px !important;
  }  */

  .new1{
    margin-top: 10px !important;
    margin-bottom: 10px;
    padding: 10px 18px;
    font-size: 10px;
    width:180px;
     
    
  }
  
   
}

@media screen and (max-width:470px) {

  .pack2{
    display: inline !important;
  }

  .back2_btn{
    display: flex;
    justify-content: center;
  }

  .pack1{
    text-align: center !important;
  }
  .new1{
    width:300px;
  }
}


@media screen  and (max-width:500px){

  .addpack{
    font-size: 15px !important;
  }

  
}

@media screen  and (max-width:446px){
  .online_pack{
    display: inline;
    text-align: center !important;
  }
  .online_pack1{
    display: flex;
    justify-content: center;
  }

  
}

.new_online_pack{
  padding: 10px 15px;
  margin-bottom: 10px;
  
}


.data-table-wrapper .rdt_TableHeadRow div {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
   
  display: flex;
  align-items: center;
   
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 15px;
  font-weight: 600;
  color:rgb(73, 73, 73)
   
  
  /* font-weight: 500 !important; */
}

.data-table-wrapper .rdt_TableCell {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  /* font-weight: 700 !important; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 8px;
   
  padding-left: 1rem !important
  
}